import React, { useRef, useLayoutEffect } from "react";
import { scrollDown } from "../../../common/ChatUtil";
import "./Notification.scss";

const Notification = (props) => {
  const { type, unreadCount } = props;
  const infoRef = useRef();

  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(infoRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(infoRef);
      }
    };
  });
  /*[informational | warning | error | confirmation] */
  return (
    <>
      <div className="-oneX-container notification-container">
        {type === "informational" && (
          <div
            ref={infoRef}
            className="-oneX-row base-notification info-notification"
            onClick={scrollDown}
            onKeyDown={scrollDown}
          >
            {/* information */}
            {/* <div className="-oneX-col-1 -oneX-icon-container -oneX-icon--interactive">
       <div 
           role="button"
           tabIndex="0"
           className="-oneX-icon info-icon"
           data-icon="information_24"
           title="Information Button"
           aria-label="Click information icon to view most recent unread messages"
           >                
       </div> 
     </div> */}
            <div tabIndex="0" className="-oneX-col-12">
              {props.children}
            </div>
          </div>
        )}
        {type === "error" && (
          <div
            className={
              "-oneX-row base-notification " +
              (unreadCount > 0 ? "add-top-padding" : "")
            }
          >
            <div className="-oneX-col">{props.children}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
