import React from "react";
import "./InteractiveMessage.css";
import { disableLink } from "../../../common/ChatUtil.js";
var fourOrMore = "";
const ResponseCard = ({
  messageText: { data },
  sendMessage,
  chatRepBubbleColor,
  delayMessage,
  isInteractiveMessageClicked,
  id,
  isNetworkError,
}) => {
  const handleKeyEvent = (event) => {
    if (event.key === "Enter" || event.key === "Space") {
      sendMessage(event.target.value);
    } else if (event.key === "Tab") {
      return false;
    }
  };
  const handleCLickEvent = async (event) => {
    try {
      document.getElementById(id).hidden = true;
      await sendMessage(event.target.value);
    } catch (error) {
      console.error("Interactive Message Catch", error);
    }
  };

  if (isInteractiveMessageClicked) {
    return (
      <div
        className={
          isNetworkError
            ? "chat-message-text message-left opacity-lost-conn"
            : "chat-message-text message-left"
        }
        data-b-color={chatRepBubbleColor}
        style={{
          backgroundColor: chatRepBubbleColor,
          borderColor: chatRepBubbleColor,
        }}
        dangerouslySetInnerHTML={{ __html: data.content.title }}
        onClick={(event) => disableLink(event, isNetworkError)}
      ></div>
    );
  } else {
    fourOrMore = "interactive-message-container";
    return (
      <div
        className="overrideCard"
        data-testid="overrideCard"
        id={id}
        hidden={delayMessage ? true : false}
      >
        {data.content.imageData && (
          <img
            alt="CardImage"
            variant="top"
            src={data.content.imageData}
            className="imageData"
          />
        )}
        {data.content.value ? (
          <div
            className={
              isNetworkError
                ? "card-title cardTitleLong card-opacity-lost-conn"
                : "card-title cardTitleLong"
            }
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: data.content.value }}
          ></div>
        ) : (
          <div
            className={
              isNetworkError
                ? "card-title card-opacity-lost-conn"
                : "card-title"
            }
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: data.content.title }}
          ></div>
        )}
        {data.content.subtitle && (
          <div
            className={
              isNetworkError
                ? "subtitleCard card-opacity-lost-conn"
                : "subtitleCard"
            }
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: data.content.subtitle }}
            id={id + "subtitle"}
          ></div>
        )}
        <div
          className={fourOrMore}
          id={id + "qr"}
          hidden={delayMessage ? true : false}
        >
          {data.content.elements.map((title, i) => (
            <button
              tabIndex={0}
              onKeyUp={(event) => handleKeyEvent(event)}
              onClick={(event) => handleCLickEvent(event)}
              key={i}
              className={
                isNetworkError
                  ? "-oneX-btn-secondary -oneX-btn-small quick-reply opacity-lost-conn"
                  : "-oneX-btn-secondary -oneX-btn-small quick-reply"
              }
              value={title.value ? title.value : title.title}
            >
              {title.title ? title.title : title.value}
            </button>
          ))}
        </div>
      </div>
    );
  }
};

export default ResponseCard;
