import moment from "moment";
import { logger } from "./Logger";

const inputContainerDefaultParameters = ({ messageList, enableTextArea }) => {
  let isinputBarEnabled = false;
  let placeholderMessage = "waitMessage";

  let foundSurveyKeyWordAtIndex = -1;
  messageList.forEach((el, index) => {
    switch (true) {
      case el.isSystem && el.messageText.templateType === "ListPicker":
        if (
          !enableTextArea &&
          el.messageText?.data?.content?.title ===
            "Would you like to participate in a brief survey?"
        ) {
          foundSurveyKeyWordAtIndex = index;
        }
      case el.isSystem && el.messageText.type === "quickReplies":
        isinputBarEnabled = enableTextArea;
        placeholderMessage = enableTextArea ? "typeMessage" : "selectOptions";
        if (enableTextArea) {
          return {
            placeholderMessage,
            isinputBarEnabled,
            foundSurveyKeyWordAtIndex,
          };
        }
        break;

      //case el.isSystem && el.messageText ===
      //     'Please provide us information that will help us improve the tools you use.  This survey is optional and you may exit. <a href="https://collab.sfcollab.org/sites/WSS003629/Shared Training Content/Agent-ATM/Survey Disclosures/Data Use Statemen_AX.pdf" target="_blank">Data Use Statement</a>':
      //  isinputBarEnabled = false;
      //  break;

      case el.isSystem &&
        el.messageUserName === "SYSTEM_MESSAGE" &&
        foundSurveyKeyWordAtIndex > -1 &&
        index === foundSurveyKeyWordAtIndex + 1:
      case el.isSystem && el.messageUserName === "BOT":
      case el.isEvent:
      case el.isAgent:
        placeholderMessage = "typeMessage";
        isinputBarEnabled = true;
        break;
    }
  });
  logger.debug("inputContainerDefaultParameters return value", {
    messageListLastMessage:
      messageList.length === 0 ? "" : messageList[messageList.length - 1],
    placeholderMessage,
    isinputBarEnabled,
    foundSurveyKeyWordAtIndex,
  });
  return { placeholderMessage, isinputBarEnabled };
};

//To parse the message sent by Iframe parent. The message will be a JSON string.
//JSON string will contain config key and customizable parameters, sent by chat widget.
//The JSON string will be extracted form message and parsed.
//Returns JSON Object
const parseParentMessage = (msg) => {
  let initialConfig = msg.substring(msg.indexOf("-") + 1);
  return JSON.parse(initialConfig);
};

//checks whether the item is of type string or JSON
function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

const getMessage = (originalMessage, scrubAPIResponse) => {
  let message = "";
  if (scrubAPIResponse.errorMessage) {
    message = originalMessage;
  } else if (scrubAPIResponse.body.Error) {
    message = originalMessage;
  } else if (scrubAPIResponse.body.isMessageScrub) {
    message = scrubAPIResponse.body.scrubMessage;
  } else {
    message = originalMessage;
  }
  return message;
};

//Find large message splitting postion
const getSplitPosition = (text, allowedLength) => {
  let splitPos = 0;
  if (text.length <= allowedLength) {
    splitPos = text.length;
  } else {
    let splitText = text && text.substr(0, allowedLength);
    splitPos = splitText.lastIndexOf(".") + 1;
    if (splitPos === 0) {
      splitPos = splitText.lastIndexOf(" ") + 1;
      if (splitPos === 0) {
        splitPos = allowedLength;
      }
    }
  }
  return splitPos;
};

const extractAliasFromEmail = (email) => {
  const index = email ? email.indexOf("@") : -1;
  if (index < 1) {
    return "";
  }
  const repUserID = email.substring(0, index);
  const lastIndex = repUserID.lastIndexOf(".");
  const repFullName = repUserID.substring(0, lastIndex).replace(".", " ");
  const repAlias = repUserID.substring(lastIndex + 1).toLowerCase();
  const repObj = {
    name: repFullName,
    alias: repAlias,
  };
  return repObj;
};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

async function downloadImage(imageSrc) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const urlCreator = URL || window.URL || window.webkitURL;
  return urlCreator.createObjectURL(imageBlog);
}

//this function converts plain text into clickable links
const convertLink = (inputText) => {
  var replacePattern1,
    replacePattern2,
    replacePattern3,
    replacePattern4,
    replacePattern5;

  let regexHref = /(<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1)/g;
  // this checks if link sent from rep is already clickable
  if (!inputText.match(regexHref)) {
    //URLs starting with http://, or https://
    replacePattern1 =
      /(\b(https?):\/\/[-A-Z0-9+&#\/%?=~_|!:,.;]*[-A-Z0-9+&#\/%=~_|])/gim;
    if (replacePattern1.test(inputText))
      inputText = inputText.replace(
        replacePattern1,
        `<a href="$1" onclick="return check('$1')" target="_blank">$1</a>`
      );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    if (replacePattern2.test(inputText))
      inputText = inputText.replace(
        replacePattern2,
        `$1<a href="http://$2" id="analytics-test" onclick="return check('$2')" target="_blank">$2</a>`
      );

    //Change email addresses to mailto:: links.
    replacePattern3 =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    if (replacePattern3.test(inputText))
      inputText = inputText.replace(
        replacePattern3,
        `<a href="mailto:$1">$1</a>`
      );
    //make phone number click to dial
    /*  replacePattern4 = /(1?-?\(?[0-9]{3}[\-\)][0-9]{3}-[0-9]{4})/g;
    if (replacePattern4.test(inputText)) {
      //var tempString = inputText;
      inputText = inputText.replace(replacePattern4, `<a href="tel:$1">$1</a>`);
      console.log("click to dial pattern matched  ", inputText);
    } */
  } else {
    replacePattern5 = /<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/gi;
    inputText = inputText.replace(
      replacePattern5,
      '<a href="$2" id="analytics-test" target="_blank">$4</a>'
    );
  }
  return inputText;
};

function getCookieByName(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//play chime audio when receives a message
const playAudio = async (isAgentJoined, soundOff) => {
  /*   console.log("values of isAgentJoined, soundOff: ", {
    isAgentJoined,
    soundOff,
  }); */
  let audio;
  var host = window.location;
  if (isAgentJoined) {
    audio = new Audio("/assets/sound/agentJoined.wav"); //agent
    audio.type = "audio/wav";
  } else {
    if (soundOff === false) {
      audio = new Audio("/assets/sound/Chord2.wav");
      audio.type = "audio/wav";
    }
  }

  try {
    await audio.play();
  } catch {
    console.error("playback prevented");
  }
};

function disableLink(event, isNetworkError) {
  if (isNetworkError) {
    event.preventDefault();
  }
}

const checkForTestScenario = (
  message,
  messageValueToTest,
  enableTest = true,
  onPassReturn = true,
  onFailReturn = false
) => {
  if (
    enableTest &&
    process.env.REACT_APP_ENV !== "prod" &&
    typeof message === "string" &&
    message.split(",").some((msg) => msg === messageValueToTest)
  ) {
    console.log("checkForTestScenario", message, messageValueToTest);
    if (onPassReturn) {
      if (onPassReturn instanceof Error) throw onPassReturn;
      else return onPassReturn;
    } else {
      return onFailReturn;
    }
  }
  return onFailReturn ? onFailReturn : false;
};

const scrollDown = () => {
  const chatMessageContainer = document.querySelector(".messageListContainer");
  if (chatMessageContainer) {
    setTimeout(() => {
      chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
    }, 0);
  }
};

export {
  parseParentMessage,
  isJson,
  getMessage,
  getSplitPosition,
  extractAliasFromEmail,
  uuidv4,
  convertLink,
  getCookieByName,
  downloadImage,
  playAudio,
  disableLink,
  checkForTestScenario,
  inputContainerDefaultParameters,
  scrollDown,
};
