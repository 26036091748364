import moment from "moment";
import axios from "axios";
import { checkForTestScenario } from "../common/ChatUtil";
import { logger } from "../common/Logger";
import {
  AzureAuthenticationLoginCancelledError,
  AzureAccessDeniedError,
  OktaAccessDeniedError,
  UnKnownError,
} from "../common/CustomError";

async function callApiWithToken(
  { config, apiEndPoint, method, body },
  retryCount = 0
) {
  console.log("xxxxxxxxx response config ", config);

  const headers = {
    "Content-Type": "application/json",
    "x-api-key": config.ChatApiKey,
  };
  console.log("header", headers);
  if (config.customerFacing === "true") {
    headers.Authorization = config.oktaAccessToken;
  } else {
    const response = await config.azureApi.getApiAccessToken();

    console.log(
      "callApiWithToken response from config.azureApi.getApiAccessToken(),",
      { response }
    );
    if (response?.accessToken) {
      headers.Authorization = response.accessToken;
    } else {
      throw new Error(
        response?.errorMessage
          ? response.errorMessage
          : "unknown Error encountered!"
      );
    }
  }

  logger.debug("ApiEndpoint in api call: " + apiEndPoint);
  logger.debug("Headers used within api: " + JSON.stringify(headers));
  try {
    const response = await axios({
      url: apiEndPoint,

      method: method,

      // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers,
      data: JSON.stringify(body), // body data type must match "Content-Type" header
    });
    const data = response.data; //await response.json(); // parses JSON response into native JavaScript objects
    if (process.env.REACT_APP_VERSION === "local") {
      console.log("callApiWithToken response data : " + JSON.stringify(data));
    }
    return data;
  } catch (error) {
    console.error("callApiWithToken error : " + error.toString());
    // when azure acess token expired, retry acquring new azure access token
    if (
      config.customerFacing !== "true" &&
      error
        .toString()
        .includes("AxiosError: Request failed with status code 403")
    ) {
      config.azureApi.setApiAccessToken({
        accessToken: undefined,
        expiresOn: 0,
      });
      if (retryCount > 3) {
        console.error(
          "callApiWithToken - exceeded number of retry for azure access token: " +
            error.toString()
        );
        throw error;
      }
      return await callApiWithToken(
        { config, apiEndPoint, method, body },
        retryCount ? retryCount++ : 1
      );
    } else {
      console.error("callApiWithToken unknown error : " + error.toString());
      throw error;
    }
  }
}

//Initiate Chat Method - This is to initiate the Chat
const initiateChatMethod = async (config, forceInitiate) => {
  /* "null" is received when ecrm has no claims ID ; "" is received for regular hercules */
  const attributes = {
    ...config.additionalAttributes,
    customerName: config.userName,
    alias: config.userIdentifier,
    producer_name: config.ConfigKey,
    ...(config.claimsId !== "" &&
      config.claimsId !== "null" && { chatSession: config.claimsId }),
  };
  uploadChatLogs(config, {
    eventStr: "initiateChatMethod",
    attributes: JSON.stringify(attributes),
    forceInitiate,
  });

  const body = {
    Attributes: attributes,
    ParticipantDetails: {
      DisplayName: config.userName,
    },
    ContactFlowId: config.ContactFlowId,
    InstanceId: config.InstanceId,
    force: forceInitiate,
  };
  const apiEndPoint = config.ChatApiURL + `/${config.NameSpace}/initiate`;
  try {
    const response = await callApiWithToken({
      config: overideApiAccessTokenBaseOnTestScenario(
        config.additionalAttributes["CTM_test"],
        "trigger-initiate-chat-403",
        "invalidTokenTest" + Math.random(),
        config
      ),
      apiEndPoint,
      method: "POST",
      body,
    });
    if (response.statusCode !== 200) {
      throw new Error(
        "inititateChat failed with status code " + response.statusCode
      );
    }
    return response;
  } catch (e) {
    console.error("error from initiate", e);
    console.error("error from initiate e.code", e.code);
    handleChatApiError(e, config, {
      eventStr: "onInitiateChatMethod-ERROR",
    });
  }
};

const uploadChatLogs = async (config, err) => {
  try {
    const apiEndPoint = config.ChatApiURL + `/${config.NameSpace}/widgetlogger`;
    const data = {
      ...err,
      userName: config.userName,
      userIdentifier: config.userIdentifier,
      sessionID: config.sessionID,
      configKey: config.ConfigKey,
      timestamp: new Date().getTime(),
    };
    const body = {
      contactId: config.customerContactID,
      err: JSON.stringify(data, Object.getOwnPropertyNames(data)),
    };
    const response = await callApiWithToken({
      config,
      apiEndPoint,
      method: "POST",
      body: { body },
    });
    return response;
  } catch (e) {
    console.info("error from uploadChatLogs", e);
    return "";
  }
};

const scrubMessage = async (config, msg) => {
  const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const body = {
    message: msg,
    requestTime: now,
  };
  try {
    checkForTestScenario(
      msg,
      "trigger-scrub-chat-error",
      true,
      new UnKnownError("test for UnKnownError during scrub")
    );

    const apiEndPoint = config.ChatApiURL + `/${config.NameSpace}/scrubchat`;
    const response = await callApiWithToken({
      config: overideApiAccessTokenBaseOnTestScenario(
        msg,
        "trigger-scrub-chat-403",
        "invalidTokenTest" + Math.random(),
        config
      ),
      apiEndPoint,
      method: "POST",
      body,
    });
    return response;
  } catch (e) {
    console.error("error from scrubchat", e);
    console.error("error from scrubchat e.code", e.code);
    handleChatApiError(e, config, {
      eventStr: "onScrubMethod-ERROR",
    });
  }
};

const getConnectAttributes = async (
  config,
  customerContactID,
  connectAttributeKeys
) => {
  const apiEndPoint =
    config.ChatApiURL + `/${config.NameSpace}/connectattributes`;
  const body = {
    attributeKeys: connectAttributeKeys,
    contactId: customerContactID,
  };

  try {
    checkForTestScenario(
      config.additionalAttributes["CTM_test"],
      "trigger-connect-attribute-error",
      true,
      new UnKnownError("test for UnKnownError during scrub")
    );

    const response = await callApiWithToken({
      config: overideApiAccessTokenBaseOnTestScenario(
        config.additionalAttributes["CTM_test"],
        "trigger-connect-attribute-403",
        "invalidTokenTest" + Math.random(),
        config
      ),
      apiEndPoint,
      method: "POST",
      body,
    });
    return response;
  } catch (e) {
    console.error("error from getConnectAttributes", e);
    console.error("error from getConnectAttributes e.code", e.code);
    handleChatApiError(e, config, {
      eventStr: "ongetConnectAttribute-repalias-ERROR",
    });
  }
};

const chatTransfer = async (config, contactId) => {
  /* "null" is received when ecrm has no claims ID ; "" is received for regular hercules */
  const attributes = {
    customerName: config.userName,
    alias: config.userIdentifier,
    producer_name: config.ConfigKey,
    ...(config.claimsId !== "" &&
      config.claimsId !== "null" && { chatSession: config.claimsId }),
  };

  const body = {
    Attributes: attributes,
    transferContactId: contactId,
  };

  const apiEndPoint = config.ChatApiURL + `/${config.NameSpace}/initiate`;

  try {
    const response = await callApiWithToken({
      config: overideApiAccessTokenBaseOnTestScenario(
        config.additionalAttributes["CTM_test"],
        "trigger-initiate-chat-transfer-403",
        "invalidTokenTest" + Math.random(),
        config
      ),
      apiEndPoint,
      method: "POST",
      body,
    });
    return response;
  } catch (e) {
    console.error("error from Transfer", e);
    console.error("error from Transfer e.code", e.code);
    handleChatApiError(e, config, {
      eventStr: "onInitiateChatTranferMethod-ERROR",
    });
  }
};

console.log("***** current local appVersion:" + process.env.REACT_APP_VERSION);
const isAppVersionLatest = async () => {
  try {
    const response = await fetch("/meta.json");
    const data = await response.json(); // parses JSON response into native JavaScript objects
    const latestAppVersion = data.appVersion;
    console.log(" latest server AppVersion:" + latestAppVersion);
    console.log(
      "***** current local appVersion:" +
        process.env.REACT_APP_VERSION +
        " latest server AppVersion:" +
        latestAppVersion
    );
    return process.env.REACT_APP_VERSION === latestAppVersion;
  } catch (error) {
    console.error("appVersionCheck error : " + error);
    return true;
  }
};

const overideApiAccessTokenBaseOnTestScenario = (
  attribute,
  attributeValue,
  replacementValue,
  config
) => {
  const tempConfig = { ...config };
  logger.debug("overideApiAccessTokenBaseOnTestScenario ,", {
    attribute,
    attributeValue,
    replacementValue,
  });
  tempConfig.oktaAccessToken = checkForTestScenario(
    attribute,
    attributeValue,
    config.customerFacing === "true"
  )
    ? replacementValue
    : config.oktaAccessToken;

  if (
    checkForTestScenario(
      attribute,
      attributeValue,
      config.customerFacing !== "true"
    )
  ) {
    config.azureApi.setApiAccessToken({
      accessToken: replacementValue,
      expiresOn: Date.now() + 45 * 60 * 1000,
    });
  }
  return tempConfig;
};

const handleChatApiError = (err, config, eventLog, throwError = true) => {
  console.log("Entered handleChatApiError()", {
    event: eventLog?.eventStr,
    err,
  });
  uploadChatLogs(config, {
    ...eventLog,
    errorMessage: JSON.stringify(err),
  });
  /// NOTE: do not add uploadChatLogs here because if the error is a 403
  //  it will cause nested errors.  you can do uploadChatLogs on a non 403 error.
  //
  if (
    config.customerFacing === "false" &&
    err instanceof AzureAuthenticationLoginCancelledError
  ) {
    throw AzureAuthenticationLoginCancelledError;
  } else if (
    config.customerFacing === "false" &&
    (err?.toString()?.includes("failed with status code 403") ||
      err?.response?.status === 403)
  ) {
    throw new AzureAccessDeniedError("Expired or Invalid Azure Token");
  } else if (
    config.customerFacing === "true" &&
    (err?.toString()?.includes("failed with status code 403") ||
      err?.response?.status === 403)
  ) {
    throw new OktaAccessDeniedError("Expired or Invalid Okta Token");
  } else {
    throw new UnKnownError(err);
  }
};

export {
  initiateChatMethod,
  uploadChatLogs,
  scrubMessage,
  chatTransfer,
  getConnectAttributes,
  isAppVersionLatest,
};
