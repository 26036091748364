import React, { useEffect, useRef, useLayoutEffect } from "react";
import "./ActionBar.scss";
import { useTranslation } from "react-i18next";

const ActionBar = ({
  printChat,
  adjustSound,
  onClickAction,
  returnFocus,
  printChatRef,
  onMuteAction,
  muteButton,
}) => {
  const { t, i18n } = useTranslation();
  const muteButtonRef = useRef();
  const unmuteButtonRef = useRef();
  const menuItemRef = useRef();
  const version = process.env.REACT_APP_VERSION
    ? "Version:" + process.env.REACT_APP_VERSION
    : "";

  useEffect(() => {
    if (
      document.activeElement === muteButtonRef.current ||
      document.activeElement === unmuteButtonRef.current
    ) {
      muteButton
        ? muteButtonRef.current.focus()
        : unmuteButtonRef.current.focus();
    }
  }, [muteButton]);

  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(menuItemRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(menuItemRef);
      }
    };
  });

  //Toggle Mute/Unmute button.
  //Adjust the sound off or not, pass value to Chat.js
  const toggleMuteUnmuteButton = (event, value) => {
    event.preventDefault();
    if (event.key === "Escape") {
      onClickAction(event);
      returnFocus("menu");
      return false;
    }
    if (event.key === "Tab") {
      if (event.shiftKey) {
        printChatRef.current.focus();
      } else {
        returnFocus("close");
      }
      return false;
    }
    if (event.key === "Enter" || event.key === "" || event.type === "click") {
      adjustSound(!value);
      onMuteAction(value);
      return false;
    }
  };

  const handlePrintChat = (event) => {
    event.preventDefault();
    if (event.key === "Escape") {
      onClickAction(event);
      returnFocus("menu");
      return false;
    }
    if (event.key === "Tab") {
      if (event.shiftKey) {
        returnFocus("close");
      } else {
        muteButton
          ? muteButtonRef.current.focus()
          : unmuteButtonRef.current.focus();
      }
      return false;
    }

    if (event.key === "Enter" || event.key === "" || event.type === "click") {
      printChat(event);
      return true;
    }
  };

  return (
    <div className="-oneX-container action-bar-container" ref={menuItemRef}>
      {/* Print Transcript code */}
      <div className="-oneX-row menu-item-row">
        <div
          tabIndex="0"
          className="-oneX-icon-container -oneX-icon--interactive "
          ref={printChatRef}
          role="button"
          id="printButtonID"
          title={t("printButtonMessage")}
          onClick={(event) => printChat(event)}
          onKeyDown={(event) => handlePrintChat(event)}
        >
          <div
            className="-oneX-icon menu-icons make-it-small"
            data-icon="external_link_24"
          ></div>
        </div>
        <div
          className="menu-text"
          role="button"
          onClick={(event) => printChat(event)}
        >
          {t("printButtonMessage")}
        </div>
      </div>

      {/* Mute/Unmute Code */}
      <div className="-oneX-row menu-item-row">
        {" "}
        <div
          tabIndex="0"
          role="button"
          className={
            muteButton
              ? "-oneX-icon-container -oneX-icon--interactive"
              : "hide-display "
          }
          title={t("muteButtonMessage")}
          id="muteButtonID"
          ref={muteButtonRef}
          onClick={(event) => toggleMuteUnmuteButton(event, false)}
          onKeyDown={(event) => {
            event.persist();
            toggleMuteUnmuteButton(event, false);
          }}
        >
          <div
            className={muteButton ? "-oneX-icon menu-icons" : "hide-display"}
            data-icon="audio_mute_24"
            onClick={(event) => toggleMuteUnmuteButton(event, false)}
          ></div>
        </div>
        <div
          className={muteButton ? "menu-text" : "hide-display"}
          role="button"
          onClick={(event) => toggleMuteUnmuteButton(event, false)}
        >
          {t("muteButtonMessage")}
        </div>
        <div
          tabIndex="0"
          role="button"
          className={
            muteButton
              ? "hide-display "
              : "-oneX-icon-container -oneX-icon--interactive"
          }
          id="unmuteButtonID"
          ref={unmuteButtonRef}
          title={t("unmuteButtonMessage")}
          onClick={(event) => toggleMuteUnmuteButton(event, true)}
          onKeyDown={(event) => {
            event.persist();
            toggleMuteUnmuteButton(event, true);
          }}
        >
          <div
            className="-oneX-icon menu-icons"
            data-icon="audio_24"
            // role="button"
          ></div>
        </div>
        <div
          className={muteButton ? "hide-display " : "menu-text"}
          role="button"
          onClick={(event) => toggleMuteUnmuteButton(event, true)}
        >
          {t("unmuteButtonMessage")}
        </div>
      </div>
      <div className="show-version">{version}</div>
    </div>
  );
};

export default ActionBar;
