export const logger = {
  loggerLevel: "info",
  cookieName: "chatLogLevel",
  debug: (...args) => {
    _log("debug", ...args);
  },
  info: (...args) => {
    _log("info", ...args);
  },
  warn: (...args) => {
    _log("warn", ...args);
  },
  error: (...args) => {
    _log("error", ...args);
  },
  setSessionLogLevel: (logLevel) => {
    try {
      let chatLogLevel =
        process.env.REACT_APP_VERSION === "local" ? "debug" : "info";
      sessionStorage.setItem("chatLogLevel", chatLogLevel);
    } catch (error) {
      return;
    }
  },
  getSessionLogLevel: () => {
    try {
      const chatLogLevel = sessionStorage.getItem("chatLogLevel");
      logger.loggerLevel =
        chatLogLevel?.toLowerCase() === "debug" ||
        chatLogLevel?.toLowerCase() === "warn" ||
        chatLogLevel?.toLowerCase() === "info" ||
        chatLogLevel?.toLowerCase() === "error" ||
        chatLogLevel?.toLowerCase() === "silent"
          ? chatLogLevel.toLowerCase()
          : "info";
    } catch (error) {
      logger.loggerLevel = "info";
    }
    return logger.loggerLevel;
  },
};

const _log = (method, ...args) => {
  const levels = {
    silent: -1,
    error: 0,
    warning: 1,
    info: 2,
    debug: 3,
  };
  try {
    if (
      levels.hasOwnProperty(method) &&
      levels.hasOwnProperty(logger.loggerLevel?.toLowerCase()) &&
      logger.loggerLevel?.toLowerCase() !== "silent" &&
      levels[method] <= levels[logger.loggerLevel?.toLowerCase()]
    ) {
      const [msg, ...meta] = args;
      console[method](
        "CHATLOG [" + new Date().toLocaleTimeString() + "]: " + msg,
        isArrayEmpty(meta) || isObjEmpty(meta)
          ? ""
          : isArraySizeOne(meta)
          ? meta[0]
          : meta
      );
    }
  } catch (e) {
    console.error("Error during _log", e);
  }
};

function isObjEmpty(obj) {
  return (
    obj &&
    Object.prototype.toString.call(obj) === "[object Object]" &&
    obj.constructor === Object &&
    Object.values(obj).length === 0
  );
}

function isArrayEmpty(arr) {
  return arr instanceof Array && arr.length === 0;
}

function isArraySizeOne(arr) {
  return arr instanceof Array && arr.length === 1;
}
