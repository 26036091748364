import React from "react";
import "./CharCounter.css";


const CharCounter = ({charCount, charLimit}) => {
return(
    <>
        <span className="char-counter">
            <span className={`${charCount>=485  ? "limit-reached" : ""}`}>{charCount || 500}</span>/{charLimit || 500}
        </span>
    </>
);
}

export default CharCounter;
