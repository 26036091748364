import React from "react";
import "./RichContent.css";
import RecursiveComponent from "./RecursiveComponent";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

const RichContent = ({
  message,
  sendMessage,
  isNetworkError,
  displayUserName,
  messageUserName,
  messageTime,
  isSystem,
}) => {
  const { t } = useTranslation();
  function disableLink(event) {
    if (isNetworkError) {
      event.preventDefault();
    }
  }
  return (
    <>
      {/* className condition for horizontal is for quote card to appear in the same row!! */}
      {/* className condition for vertical is for agent card to all rounded */}
      {displayUserName && (
        <div className="messageContainer">
          <span className="displayname left">
            {isSystem ? t(messageUserName) : messageUserName}
            {" - "}
            <Moment date={messageTime} format="hh:mm A" />
          </span>
          {/* <span className="meesage-time right">{messageTime}</span> */}
        </div>
      )}
      <div>
        <div
          id={message.id}
          hidden={message.delayMessage ? true : false}
          //onClick={(event) => disableLink(event)}
          onClick={(event) => disableLink(event, isNetworkError)}
          className={`mainRichContent ${
            message.messageText.type === "carousel" ? "no-border" : ""
          }`}
        >
          <RecursiveComponent
            message={message.messageText}
            sendMessage={sendMessage}
            isNetworkError={isNetworkError}
          />
        </div>
      </div>
    </>
  );
};

export default RichContent;
