import React from "react";
import "./Loader.css";

const Loader = ({ loaderMessage }) => {
  console.log("Entered Loader with loaderMessage:", { loaderMessage });
  return (
    <>
      <div className="-oneX -oneX-container chat-loader">
        <div className="-oneX-page-loader" tabIndex="-1" id="loaderId">
          <div
            className="-oneX-modal-heading"
            role="heading"
            aria-level="2"
            tabIndex="-1"
          >
            {loaderMessage}
          </div>
          <div className="-oneX-loader-icon"></div>

          <div
            className={
              "-oneX-loaderdiv " + (loaderMessage !== "" ? "add-padding" : "")
            }
          >
            {loaderMessage === "" ? (
              <svg className="loader-white">
                <circle
                  id="circleLoadingID"
                  cx="10"
                  cy="10"
                  r="9"
                  viewBox="0 0 20 20"
                ></circle>
              </svg>
            ) : (
              <>
                <svg className="-oneX-small">
                  <circle cx="32" cy="32" r="28.8" viewBox="0 0 64 64"></circle>
                </svg>

                <svg className="-oneX-medium">
                  <circle cx=" 40" cy="40" r="36" viewBox="0 0 80 80"></circle>
                </svg>

                <svg className="-oneX-large">
                  <circle cx="50" cy="50" r="45" viewBox="0 0 100 100"></circle>
                </svg>
              </>
            )}
          </div>
        </div>
      </div>

      {/*----Old code with no 1x----
    <div id="statusMessage" className="alertmsg" >{loaderMessage}</div>
    <div id='ChatSpinner' style={{ borderTopColor: dynamicStyle.chatWindowColor }}></div> */}
    </>
  );
};

export default Loader;
