import React from "react";
import { uuidv4 } from "../../../common/ChatUtil.js";

const Vertical = ({
  elements,
  style,
  richContentMap,
  sendMessage,
  isNetworkError,
}) => {
  const children = elements.map((message) => {
    const Ele = richContentMap[message.type];

    return (
      <Ele
        key={uuidv4()}
        {...message}
        sendMessage={sendMessage}
        richContentMap={richContentMap}
        isNetworkError={isNetworkError}
      />
    );
  });
  return (
    <div
      id={uuidv4()}
      className={isNetworkError ? "vertical opacity-lost-conn" : "vertical"}
      style={style}
    >
      {children}
    </div>
  );
};
export default Vertical;
