import React, { useRef, useLayoutEffect, useEffect } from "react";
import Moment from "react-moment";
import InteractiveMessage from "../InteractiveMessage/InteractiveMessage";
import "./Message.css";
import { useTranslation } from "react-i18next";
import { disableLink, scrollDown } from "../../../common/ChatUtil.js";
const Message = ({
  messageText,
  messageUserName,
  isCustomer,
  isAgent,
  isSystem,
  isEvent,
  displayUserName,
  messageTime,
  id,
  repAlias,
  delayMessage,
  isNetworkError,
  isInteractiveMessageClicked,
  sendMessage,
  readDeliveryStatus,
}) => {
  const errorMessageRef = useRef();
  const { t } = useTranslation();
  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(errorMessageRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(errorMessageRef);
      }
    };
  }, []);

  useEffect(() => {
    scrollDown();
  }, [readDeliveryStatus]);

  return (
    <>
      <div
        className={displayUserName ? "messageContainer" : "messageContainer1"}
      >
        {isCustomer && (
          <>
            <div className="messageRightContainer">
              {displayUserName && (
                <div>
                  <span
                    className={
                      isNetworkError
                        ? "message-time right opacity-lost-conn"
                        : "message-time right"
                    }
                  >
                    <Moment date={messageTime} format="hh:mm A" />
                  </span>
                  <span
                    id="customerResponse"
                    className={
                      isNetworkError
                        ? "displayname right opacity-lost-conn"
                        : "displayname right"
                    }
                    data-testid="displaynameRight"
                  >
                    {t("you")}
                  </span>
                </div>
              )}

              <div className="chat-message rightContent">
                <div
                  className={
                    isNetworkError
                      ? "chat-message-text message-right opacity-lost-conn"
                      : "chat-message-text message-right"
                  }
                  data-testid="messageRight"
                  dangerouslySetInnerHTML={{ __html: messageText }}
                  onClick={(event) => disableLink(event, isNetworkError)}
                ></div>
              </div>

              {readDeliveryStatus !== "None" && (
                <div>
                  <span
                    className={
                      isNetworkError
                        ? "displayname right opacity-lost-conn"
                        : "displayname right"
                    }
                  >
                    {t(readDeliveryStatus)}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        {isEvent && (
          <div className="chat-message">
            <div
              className="chat-message-text-center"
              data-testid="messageTextCenter"
              dangerouslySetInnerHTML={{ __html: messageText }}
            />
          </div>
        )}

        {(isSystem || isAgent) && (
          <>
            {displayUserName && (
              <div>
                {isSystem && <div className="newMessageIndicator">New</div>}
                <span
                  className={
                    isNetworkError
                      ? "displayname left opacity-lost-conn"
                      : "displayname left"
                  }
                  data-testid="displayname"
                >
                  {isSystem ? t(messageUserName) : messageUserName}{" "}
                  {isAgent ? repAlias ? <>({repAlias}) </> : <></> : <></>}-
                </span>
                <span
                  className={
                    isNetworkError
                      ? "message-time left opacity-lost-conn"
                      : "message-time left"
                  }
                >
                  <Moment date={messageTime} format="hh:mm A" />
                </span>
              </div>
            )}

            <div className="chat-message leftContent">
              {isSystem &&
              messageText &&
              messageText.templateType === "ListPicker" ? (
                <InteractiveMessage
                  messageText={messageText}
                  sendMessage={sendMessage}
                  isInteractiveMessageClicked={isInteractiveMessageClicked}
                  delayMessage={delayMessage}
                  id={id}
                  isNetworkError={isNetworkError}
                />
              ) : (
                <div
                  className={
                    isNetworkError
                      ? "chat-message-text message-left opacity-lost-conn"
                      : "chat-message-text message-left"
                  }
                  id={id}
                  hidden={delayMessage ? true : false}
                  dangerouslySetInnerHTML={{ __html: messageText }}
                ></div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Message;
