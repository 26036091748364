import { InteractionRequiredAuthError } from "@azure/msal-common";
import { EventType } from "@azure/msal-browser";
import { callMsGraph } from "./graphAPI";
import moment from "moment";
import { extractAliasFromEmail } from "../common/ChatUtil";
import {
  AzureAuthenticationLoginCancelledError,
  AzureAuthenticationLoginPopupBlockedError,
  AzureAuthenticationLoginPopupUnknownError,
  AzureAuthenticationSSOSilentUnknownError,
  AzureAuthenticationTokenSilentUnknownError,
} from "../common/CustomError";

class AzureAPI {
  instance;
  account;
  graphScopes;
  graphAccessToken;
  userName;
  userIdentifier;
  apiScopes;
  apiAccessToken = { accessToken: undefined, expiresOn: undefined };
  popupWindow = null; //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5006

  constructor({ instance, graphScopes, apiScopes }) {
    this.instance = instance;
    this.graphScopes = graphScopes;
    this.apiScopes = apiScopes;

    //register an event handler to MSAL to capture the popupWindow of the MFA
    this.instance.addEventCallback((message) => {
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#table-of-events
      if (message.eventType === EventType.POPUP_OPENED) {
        // Save the popup window for focusing later
        console.log("AzureAPI: addEventCallback for POPUP_OPENED ");
        this.popupWindow = message.payload.popupWindow;
      }
      // Update UI or interact with EventMessage here
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        console.log("AzureAPI: addEventCallback for LOGIN_SUCCESS ");
        this.account = message.payload.account;
      }
    });
  }

  //https://stackoverflow.com/questions/72417925/how-to-close-msal-browser-pop-up-using-js
  closeMFAModal() {
    console.log("AzureAPI: Entered closeMFAModal()");
    if (this.popupWindow) {
      this.popupWindow.close();
    }
  }

  focusMFAModal(event) {
    console.log("AzureAPI: Entered focusMFAModal()");
    console.log("You've focused in : " + event?.target?.id);
    if (
      this.popupWindow &&
      !this.popupWindow.closed &&
      this.popupWindow.focus
    ) {
      console.log("now focus the mfa popup");
      this.popupWindow.focus();
    }
  }

  //MFA
  async azureAuthentication() {
    console.log("AzureAPI: Entered azureAuthentication()");
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#account-apis
    this.setApiAccessToken({ accessToken: undefined, expiresOn: undefined });
    try {
      const response = await this.instance.ssoSilent({
        scopes: this.graphScopes,
        prompt: "none",
      });
      console.log(
        "AzureAPI: Azure Login Successful by ssoSilent. homeAccountId: " +
          response.account.homeAccountId,
        response
      );
      this.account = response.account;
      return {
        status: "success",
      };
    } catch (err) {
      // InteractionRequiredError: will be thrown if consent is required, user needs to perform MFA and etc. This error can often be handled by simply initiating an interactive API.
      if (err instanceof InteractionRequiredAuthError) {
        // to trigger this error , clear the browser cache.
        console.log("AzureAPI: Azure Login by loginPopup.", this.popupWindow);
        // If the popup window is already open, focus it
        if (
          this.popupWindow &&
          !this.popupWindow.closed &&
          this.popupWindow.focus
        ) {
          this.popupWindow.focus();
        } else {
          // Otherwiser open a new popup window

          try {
            const loginResponse = await this.instance.loginPopup({
              scopes: this.graphScopes,
            });
            console.log(
              "AzureAPI: Azure Login Successful by loginPopup. homeAccountId: " +
                loginResponse.account.homeAccountId,
              loginResponse
            );

            this.account = loginResponse.account;
            return {
              status: "success",
            };
          } catch (error) {
            console.log("AzureAPI: Azure loginPopup failed : ", error);
            console.log(
              "AzureAPI: is popups blocked?: " +
                error.toString().includes("popups are blocked")
            );
            //   3. Azure loginPopup failed :  BrowserAuthError: post_request_failed: Network request failed: If the browser threw a CORS error, check that the redirectUri is registered in the Azure App Portal as type 'SPA' | Network client threw: TypeError: Failed to fetch | Attempted to reach: https://login.microsoftonline.com/fa23982e-6646-4a33-a5c4-1a848d02fcc4/oauth2/v2.0/token
            //   at BrowserAuthError.AuthError [as constructor] (AuthError.ts:45:1)
            //   at new BrowserAuthError (BrowserAuthError.ts:195:1)
            //   at BrowserAuthError.createPostRequestFailedError (BrowserAuthError.ts:439:1)
            //   at FetchClient.<anonymous> (FetchClient.ts:65:1)
            //   at step (0.chunk.js:198:17)
            //   at Object.throw (0.chunk.js:129:14)
            //   at rejected (0.chunk.js:91:32)

            //BrowserAuthError: user_cancelled: User cancelled the flow.
            if (error.toString().includes("user_cancelled")) {
              throw new AzureAuthenticationLoginCancelledError(
                "User authentication did not complete.  Click Retry to re-launch authentication."
              );
            } else if (error.toString().includes("popups are blocked")) {
              throw new AzureAuthenticationLoginPopupBlockedError(
                "User verification did not complete.  Click Retry."
              );
            } else {
              throw new AzureAuthenticationLoginPopupUnknownError(
                "Unexpected error in user authentication.  Click Retry to re-launch."
              );
            }
          }
        }
      } else {
        // another error that can happen is:
        // https://kksimplifies.com/msal-broswer-browserautherror/
        // BrowserAuthError: monitor_window_timeout: Token acquisition in iframe failed due to timeout. For more visit: aka.ms/msaljs/browser-errors.

        console.log(
          "AzureAPI:  Azure ssoSilent failed and not InteractionRequiredAuthError (unknownError): " +
            err
        );
        throw new AzureAuthenticationSSOSilentUnknownError(
          "Click Retry to initiate user authentication."
        );
      }
    }
  }

  //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
  async getAzureAccessToken(scopes) {
    console.log("AzureAPI: Entered getAzureAccessToken()", {
      account: this.account,
      scopes,
    });

    try {
      if (!this.account) {
        console.log("AzureAPI: getAzureAccessToken missing account");
        throw new InteractionRequiredAuthError("no account");
      }
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
      const response = await this.instance.acquireTokenSilent({
        scopes,
        account: this.account,
        // forceRefresh: true,
        // refreshTokenExpirationOffsetSeconds: 7200 // 2 hours * 60 minutes * 60 seconds = 7200 seconds
      });
      console.log("AzureAPI: successful with acquireTokenSilent.");
      const accessToken = response?.accessToken;
      const expiresOn = moment(response?.expiresOn).valueOf(); // converting to epoch milliseconds
      if (process.env.REACT_APP_VERSION === "local") {
        console.log("AzureAPI: getAzureAccessToken response", {
          accessToken,
          expiresOn,
          response,
        });
      }
      return {
        accessToken,
        expiresOn,
      };
    } catch (error) {
      console.log("AzureAPI: Error on getAzureAccessToken : ", error);
      if (error instanceof InteractionRequiredAuthError) {
        console.log(
          "AzureAPI: Error on getAzureAccessToken calling azureAuthentication()."
        );
        await this.azureAuthentication();
        return await this.getAzureAccessToken(scopes);
      } else {
        console.log(
          "AzureAPI: Error on getAzureAccessToken unknownError:",
          error
        );
        throw new AzureAuthenticationTokenSilentUnknownError(error.toString());
      }
    }
  }

  setApiAccessToken({ accessToken, expiresOn }) {
    console.log("AzureAPI: Entered setApiAccessToken()", { expiresOn });
    this.apiAccessToken = { accessToken, expiresOn };
  }

  async getApiAccessToken() {
    console.log("AzureAPI: Entered getApiAccessToken()");
    const now = Date.now();
    const mstimeRemainingBeforeRequestingNewToken =
      (this.apiAccessToken?.expiresOn ? this.apiAccessToken.expiresOn : 0) -
      600000 -
      now;
    console.log(
      "AzureAPI: getApiAccessToken - mstimeRemainingBeforeRequestingNewToken",
      mstimeRemainingBeforeRequestingNewToken
    );
    if (
      this.apiAccessToken?.accessToken &&
      this.apiAccessToken?.expiresOn - 600000 > now //expiring 10 minutes (i.e: 10 * 60 * 1000) before the expiresOn time
    ) {
      if (process.env.REACT_APP_VERSION === "local") {
        console.log(
          "AzureAPI: getApiAccessToken - retrieved cached apiAccessToken",
          {
            apiAccessToken: this.apiAccessToken,
            mstimeRemainingBeforeRequestingNewToken,
          }
        );
      }
      return this.apiAccessToken;
    } else {
      const response = await this.getAzureAccessToken(this.apiScopes);
      console.log(
        "AzureAPI: getApiAccessToken - getAzureAccessToken response",
        { response }
      );
      if (response?.accessToken) {
        this.apiAccessToken.accessToken = response.accessToken;
        this.apiAccessToken.expiresOn = response["expiresOn"];
        return this.apiAccessToken;
      } else {
        this.apiAccessToken.accessToken = undefined;
        this.apiAccessToken.expiresOn = undefined;
        return response;
      }
    }
  }

  async getUserIdentity() {
    console.log("AzureAPI: Entered getUserIdentity()");
    const response = await this.getAzureAccessToken(this.graphScopes);

    if (response?.accessToken) {
      this.graphAccessToken = response.accessToken;
      const graphResponse = await callMsGraph(this.graphAccessToken);
      this.userIdentifier = extractAliasFromEmail(
        graphResponse.userPrincipalName
      ).alias;
      this.userName = graphResponse.displayName;
      console.log("AzureAPI: Graph Data: " + JSON.stringify(graphResponse));
      return { userIdentifier: this.userIdentifier, userName: this.userName };
    } else {
      console.log("AzureAPI: No Graph Data found.", response);
      return response;
    }
  }
}

export { AzureAPI };
