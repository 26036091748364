import React from "react";
import "./InfoBar.css";
import "../InputContainer/InputContainer.css";
// Kicks off the process of finding <i> tags and replacing with <svg>
//dom.watch()
const InfoBar = ({ title, subtitle, minimizeChat, onClickCloseChatButton }) => {
  return (
    <div className="-oneX-container infoBarContainer">
      <div className="-oneX-row">
        <div className="-oneX-col-7 user-info" role="heading" aria-level="2">
          <img
            src="/image/3ovalslogo.svg"
            className="sfLogo-sf"
            aria-label="State Farm Logo"
            alt="State Farm Logo"
          />
          <div className="state-farm-represent">{title}</div>
        </div>
        <div className="-oneX-col-5 end-chat">
          {/* Hide Minimize for PACE */}
          {minimizeChat && (
            <button
              id="minimizeId"
              className="cw-chat-hide close-button"
              aria-label="Minimize screen"
              title="Minimize screen"
              onClick={(event) => minimizeChat(event)}
            >
              <img
                src="/image/minimize.svg"
                className="minimize"
                aria-label="minimize"
                alt="Minimize"
              />
            </button>
          )}
          <button
            id="endChatId"
            type="submit"
            title="Close screen"
            className="cw-chat-hide end-button"
            onClick={(event) => onClickCloseChatButton(event)}
          >
            <img
              src="/image/close.svg"
              className="close-end"
              aria-label="close"
              alt="Close"
            />
          </button>
        </div>
      </div>
      {/* "null" is received when ecrm has no claims ID ; "" is received for regular hercules */}
      {subtitle && (
        <div className="-oneX-row -oneX-body--secondary claims-id">
          {subtitle}
        </div>
      )}
    </div>
  );
};
export default InfoBar;
