import {
  UnKnownError,
  ConfigKeyNotFoundError,
  ConfigKeyMissingDataError,
} from "../common/CustomError";

//    happens during the click of the chat button and doesnt get called during a minimize
//Loads configuration when config key is available
const getArcConfig = async (config) => {
  console.log(
    "getArcConfig - Loads configuration using config key:" + config.ConfigKey
  );

  try {
    const response = await getChatConfig(config.ConfigKey);
    if (!response || Object.keys(response).length === 0) {
      console.error("configKey not found by the ARC service", config.ConfigKey);
      throw new ConfigKeyNotFoundError("Config key not found");
    }
    const chatConfig = generateConfig(response);
    Object.keys(chatConfig).forEach((key) => {
      config[key] = chatConfig[key];
    });
  } catch (err) {
    console.error(" activeRegionConfigError", err);
    throw new UnKnownError(err.toString());
  }
  if (
    !config.NameSpace ||
    !config.ChatApiURL ||
    !config.ChatApiKey ||
    !config.ChatApiURL ||
    !config.ContactFlowId ||
    !config.InstanceId
  ) {
    throw new ConfigKeyMissingDataError(
      "Config key not found. Contact platform team, WG10719."
    );
  }
  config.isConfigLoaded = true;
  if (config.ChatApiURL.includes(".c1.")) {
    config.customerFacing = "true";
  } else {
    config.customerFacing = "false";
  }
  return config;
};

//This function is to generate ARC service Promise for the config key.
const getChatConfig = async (configKey) => {
  //return axios.get(process.env.REACT_APP_ARC_SERVICE_URL+'?ConfigKey='+configKey);
  const apiEndPoint =
    process.env.REACT_APP_ARC_SERVICE_URL + "?ConfigKey=" + configKey;
  const response = await fetch(apiEndPoint, {
    method: "GET",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  });
  try {
    const data = response ? await response.json() : {};
    console.log("data : " + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error("unknow error found on getChatConfig", error, response);
    return {};
  }
};

//This function is to generate a config JSON from  ARC API response
//Returns JSON object
const generateConfig = (config) => {
  let chatConfig = {
    ChatApiURL: config?.ChatApiURL,
    InstanceId: config?.InstanceId,
    ContactFlowId: config?.ContactFlowId,
    ChatApiKey: config?.ChatApiKey,
    RegionName: config?.RegionName,
    NameSpace: config?.NameSpace,
  };
  return chatConfig;
};

export { getArcConfig };
