import React from "react";
import { uuidv4 } from "../../../common/ChatUtil.js";

const Horizontal = ({
  elements,
  style,
  richContentMap,
  sendMessage,
  isNetworkError,
}) => {
  const children = elements.map((message) => {
    const Ele = richContentMap[message.type];
    const styleWidth = (1 / elements.length) * 100 + "%";
    const childStyle = {
      width: styleWidth,
      ...message.style,
    };
    return (
      <Ele
        {...message}
        style={childStyle}
        sendMessage={sendMessage}
        richContentMap={richContentMap}
        isNetworkError={isNetworkError}
        key={uuidv4()}
      />
    );
  });
  return (
    <div className="horizontal" style={style}>
      {children}
    </div>
  );
};
export default Horizontal;
