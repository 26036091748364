import React, { useRef, useState, useLayoutEffect } from "react";
import "./Carousel.scss";
import { useTranslation } from "react-i18next";
import { uuidv4 } from "../../../common/ChatUtil.js";

const Carousel = ({
  elements,
  richContentMap,
  sendMessage,
  isNetworkError,
}) => {
  const children = elements.map((message, i) => {
    const Ele = richContentMap[message.type];

    return (
      <div
        role="listitem"
        id={i}
        key={i}
        className={
          isNetworkError ? "carouselItem opacity-lost-conn" : "carouselItem"
        }
        onKeyDown={(event) => localStorage.setItem("index", Number(i))}
        tabIndex={0}
      >
        <Ele
          {...message}
          sendMessage={sendMessage}
          richContentMap={richContentMap}
          isNetworkError={isNetworkError}
        />
      </div>
    );
  });
  const { t, i18n } = useTranslation();
  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrollStart, setScrollStart] = useState(true);
  const carouselChevronRight = useRef();
  const carouselChevronLeft = useRef();
  const carouselChevron = useRef();
  const carouselActionRef = useRef(null);
  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(carouselChevron.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(carouselChevron);
      }
    };
  });
  // Horizontal scroll in carousel
  const scrollHorizontal = (shift, event) => {
    if (isNetworkError) {
      event.preventDefault();
    } else {
      if (event.key === "Shift") {
        return;
      }
      carouselActionRef.current.scrollLeft += shift;
      //For checking if the scroll has ended
      if (
        Math.floor(
          carouselActionRef.current.scrollWidth -
            carouselActionRef.current.scrollLeft
        ) <= carouselActionRef.current.offsetWidth
      ) {
        setScrollEnd(true);
        setScrollStart(false);
      } else {
        if (carouselActionRef.current.scrollLeft === 0) {
          setScrollStart(true);
        } else {
          setScrollStart(false);
        }
        setScrollEnd(false);
      }
    }
  };

  return (
    <>
      <div id={uuidv4()} className="blockCarousel">
        <div
          id={uuidv4()}
          className="carouselBody"
          ref={carouselActionRef}
          role="list"
          title={"list of " + elements?.length + " items"}
        >
          {children}
        </div>
        <div ref={carouselChevron} className="chevronBlock">
          {!scrollStart && (
            <div className="-oneX-icon-container -oneX-icon--interactive leftBlock">
              <div
                ref={carouselChevronLeft}
                role="button"
                tabIndex={0}
                data-icon="chevron_32"
                onClick={(event) => scrollHorizontal(-208, event)}
                onKeyDown={(event) => scrollHorizontal(-208, event)}
                title={t("leftSliderTitle")}
                aria-label={t("leftSliderAriaLabel")}
                className={
                  isNetworkError
                    ? "-oneX-icon leftChevron opacity-lost-conn"
                    : "-oneX-icon leftChevron"
                }
              ></div>
            </div>
          )}
          {scrollStart && (
            <div className="-oneX-icon-container -oneX-icon--interactive blockClick ">
              <div
                ref={carouselChevronLeft}
                data-icon="chevron_32"
                className={
                  isNetworkError
                    ? "-oneX-icon leftChevron opacity-lost-conn"
                    : "-oneX-icon leftChevron"
                }
              ></div>
            </div>
          )}
          {!scrollEnd && (
            <div className="-oneX-icon-container -oneX-icon--interactive rightBlock">
              <div
                ref={carouselChevronRight}
                role="button"
                aria-label={t("rightSliderAriaLabel")}
                tabIndex={0}
                data-icon="chevron_32"
                onClick={(event) => scrollHorizontal(208, event)}
                onKeyDown={(event) => scrollHorizontal(208, event)}
                className={
                  isNetworkError
                    ? "-oneX-icon rightChevron opacity-lost-conn"
                    : "-oneX-icon rightChevron"
                }
                title={t("rightSliderTitle")}
              ></div>
            </div>
          )}
          {scrollEnd && (
            <div className="-oneX-icon-container -oneX-icon--interactive blockClick">
              <div
                ref={carouselChevronRight}
                data-icon="chevron_32"
                className={
                  isNetworkError
                    ? "-oneX-icon rightChevron opacity-lost-conn"
                    : "-oneX-icon rightChevron"
                }
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Carousel;
