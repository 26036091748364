import React from "react";
import "./BotWaitingIndicator.scss";

const BotWaitingIndicator = ({ isNetworkError }) => {
  return (
    <>
      <div className="messageContainer">
        <div className="chat-message">
          <div
            className={
              isNetworkError
                ? "chat-message-text-waiting message-left opacity-lost-conn"
                : "chat-message-text-waiting message-left"
            }
          >
            <div id="bot-waitingdot-wave">
              <span
                className={
                  isNetworkError
                    ? "bot-waiting-dot opacity-bot-waiting-dot"
                    : "bot-waiting-dot"
                }
              ></span>
              <span
                className={
                  isNetworkError
                    ? "bot-waiting-dot opacity-bot-waiting-dot"
                    : "bot-waiting-dot"
                }
              ></span>
              <span
                className={
                  isNetworkError
                    ? "bot-waiting-dot opacity-bot-waiting-dot"
                    : "bot-waiting-dot"
                }
              ></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BotWaitingIndicator;
