import React from "react";
import "./ErrorPage.css";
import {
  AzureAccessDeniedError,
  OktaAccessDeniedError,
  ConnectAccessDeniedError,
  // UnKnownError,
  ConfigKeyNotFoundError,
  ConfigKeyMissingDataError,
  ChatWidgetVersionMismatchError,
  AzureAuthenticationLoginInprogressError,
  SendMessageUnexpectedError,
  AzureAuthenticationLoginCancelledError,
  AzureAuthenticationLoginPopupBlockedError,
  AzureAuthenticationLoginPopupUnknownError,
  AzureAuthenticationSSOSilentUnknownError,
  AzureAuthenticationTokenSilentUnknownError,
  // NetworkingError,
} from "../../common/CustomError";
import { useTranslation } from "react-i18next";
let errorMessageHeader;
let errorMessage;
let actionName;
let actiontype;
let action;

const ErrorPage = ({ errorCode, error, buttonAction, linkAction }) => {
  const { t } = useTranslation();
  errorMessageHeader = undefined;
  actionName = "retryButtonMessage";
  actiontype = "button";
  action = buttonAction;
  switch (true) {
    case error instanceof ConfigKeyNotFoundError:
      errorMessage = "Config key not found";
      break;
    case error instanceof ConfigKeyMissingDataError:
      errorMessage = "Config key not found. Contact platform team, WG10719.";
      break;
    case error instanceof ChatWidgetVersionMismatchError:
      errorMessageHeader = t("Version Mismatch");
      errorMessage = t("newVersionErrMsg");
      actionName = "refreshButtonMessage";
      break;
    case error instanceof AzureAuthenticationLoginCancelledError:
      errorMessage = t("AzureAuthenticationError");
      break;
    case error instanceof AzureAuthenticationLoginPopupBlockedError:
      errorMessage = "User verification did not complete.  Click Retry.";
      break;
    case error instanceof AzureAuthenticationLoginPopupUnknownError:
      errorMessage =
        "Unexpected error in user authentication.  Click Retry to re-launch.";
      break;
    case error instanceof AzureAuthenticationSSOSilentUnknownError:
      errorMessage = "Click Retry to initiate user authentication.";
      break;
    case error instanceof AzureAuthenticationLoginInprogressError:
      actiontype = "";
      errorMessageHeader = t("SessionExpired");
      errorMessage =
        "The security token expired or could not be retrieved.  If you want to continue, enter your credentials in the user authentication window and you will be redirected back to your chat session.";
      break;
    case error instanceof OktaAccessDeniedError:
      errorMessageHeader = t("SessionExpired");
      errorMessage = t("InvalidCredentialError");
      actiontype = "link";
      actionName = "Login";
      action = linkAction;
      break;
    case error instanceof ConnectAccessDeniedError:
      errorMessage = t("chatExpired");
      break;
    case error instanceof SendMessageUnexpectedError:
      errorMessage = t("unexpectedErrMsg2");
      actionName = "Return";
      break;

    case error instanceof AzureAccessDeniedError:
    case error instanceof AzureAuthenticationTokenSilentUnknownError:
    default:
      errorMessage = t("unexpectedErrMsg");
  }

  return (
    <>
      <div className="-oneX-container error-container">
        {errorMessageHeader && (
          <div id="errorHeaderID" className="-oneX-row errorMessageHeaderRow">
            <div className="-oneX-col-12">{errorMessageHeader}</div>
          </div>
        )}
        <div id="errorMessageID" className="-oneX-row errorMessageRow">
          {errorMessage}
        </div>
        {actiontype === "link" && (
          <div className="-oneX-row retry-button">
            <a href={action} target="_parent">
              <button className="-oneX-btn-secondary retry-button">
                {t(actionName)}
              </button>
            </a>
          </div>
        )}
        {actiontype === "button" && (
          <div className="-oneX-row retry-button">
            <button
              type="submit"
              className="-oneX-btn-primary"
              onClick={(event) => action(event)}
            >
              {t(actionName)}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ErrorPage;
