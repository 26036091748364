import React from "react";

const Text = ({ text, style, isNetworkError }) => (
  <div
    className={isNetworkError ? "text opacity-lost-conn" : "text"}
    style={style}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default Text;
