import React, { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Chat from "./components/Chat/Chat.js";
import "./i18n";

const DYNATRACE_RUM_AGENT_SCRIPT =
  process.env.REACT_APP_DYNATRACE_RUM_AGENT_SCRIPT;

const App = () => {
  useEffect(() => {
    if (
      DYNATRACE_RUM_AGENT_SCRIPT &&
      DYNATRACE_RUM_AGENT_SCRIPT !== "NONE" &&
      !document.querySelector(`script[src="${DYNATRACE_RUM_AGENT_SCRIPT}"]`)
    ) {
      const script = document.createElement("script");
      script.src = DYNATRACE_RUM_AGENT_SCRIPT;
      script.crossOrigin = "anonymous";
      script.type = "text/javascript";
      document.head.appendChild(script);
    }
  }, [DYNATRACE_RUM_AGENT_SCRIPT]);

  return (
    <div className="App" aria-live="polite">
      <Suspense fallback={null}>
        <Chat />
      </Suspense>
    </div>
  );
};

export default App;
