import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./PreChatForm.css";

const PreChatForm = ({
  userName,
  userIdentifier,
  setNameAndInitiateChat,
  formInputRef,
}) => {
  const [nameUser, setNameUser] = useState(userName);
  const [idUser, setIdUser] = useState(userIdentifier);
  const [continueButtonDisable, setContinueButtonDisable] = useState(true);
  const preChatRef = useRef();

  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(preChatRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(preChatRef);
      }
    };
  }, []);

  useEffect(() => {
    setContinueButtonDisable(nameUser === "" || idUser === "");
  }, [nameUser, idUser]);

  useEffect(() => {
    if (!userName) {
      setNameUser("");
    }

    if (!userIdentifier) {
      setIdUser("");
    }

    if (window.oneX) {
      window.oneX.addElement(preChatRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(preChatRef);
      }
    };
  }, [userName, userIdentifier]);

  const validateForm = (event, nmUsr, idUsr) => {
    event.preventDefault();
    if (nmUsr !== "" && idUsr !== "") setNameAndInitiateChat(nmUsr, idUsr);
    return false;
  };

  return (
    <div
      ref={preChatRef}
      className="-oneX-container pre-chat-form"
      aria-live="polite"
    >
      <div className="-oneX-row input-fields">
        {userName === "" && (
          <>
            <label
              id="userNameLabelId"
              htmlFor="userNameId"
              className="-oneX-textfield__floatingLabel"
            >
              Username
            </label>
            <input
              type="text"
              name="nameUser"
              id="userNameId"
              value={nameUser}
              ref={formInputRef}
              className="-oneX-textfield--floating-input"
              aria-label="User name input field"
              aria-required="false"
              aria-labelledby="userNameLabelId"
              onChange={({ target: { value } }) => setNameUser(value)}
              onKeyPress={(event) =>
                event.key === "Enter"
                  ? validateForm(event, nameUser, idUser)
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="-oneX-row input-fields">
        {userIdentifier === "" && (
          <>
            <label
              id="userIdentifierLabelId"
              htmlFor="userIdentifierId"
              className="-oneX-textfield__floatingLabel"
            >
              Alias
            </label>
            <input
              type="text"
              name="idUser"
              id="userIdentifierId"
              value={idUser}
              className="-oneX-textfield--floating-input"
              aria-label="User Identifier input field"
              aria-required="false"
              aria-labelledby="userIdentifierLabelId"
              onChange={({ target: { value } }) => setIdUser(value)}
              onKeyPress={(event) =>
                event.key === "Enter"
                  ? validateForm(event, nameUser, idUser)
                  : null
              }
            />
          </>
        )}
      </div>

      <div className="-oneX-row continue-button">
        <button
          className="-oneX-btn-primary"
          type="submit"
          disabled={continueButtonDisable}
          onClick={(event) => setNameAndInitiateChat(nameUser, idUser)}
        >
          Continue
        </button>
      </div>
    </div>

    //-------Old Code with no 1x-------
    // <div className="PreChatForm">
    //   <div className="hercules-form-div">
    //     {userName === '' &&
    //       <div className="hercules-element">
    //         <div className="hercules-field">
    //           <input type="text"
    //             name="nameUser" id="nameUser"
    //             value={nameUser} ref={formInputRef}
    //             placeholder="Enter your Name"
    //             onChange={({ target: { value } }) => setNameUser(value)}
    //             onKeyPress={event => event.key === 'Enter' ? validateForm(event, nameUser, idUser) : null} />
    //           <label htmlFor="nameUser">User Name</label>
    //         </div>
    //       </div>
    //     }
    //     {userIdentifier === '' &&
    //       <div className="hercules-element">
    //         <div className="hercules-field">
    //           <input type="text"
    //             name="idUser" id="idUser"
    //             value={idUser}
    //             placeholder={"Enter your " + userIdentifierLabel}
    //             onChange={({ target: { value } }) => setIdUser(value)}
    //             onKeyPress={event => event.key === 'Enter' ? validateForm(event, nameUser, idUser) : null} />
    //           <label htmlFor="idUser">{userIdentifierLabel}</label>
    //         </div>
    //       </div>
    //     }
    //     <div className="hercules-button-area">
    //       <button type="submit" style={{ 'backgroundColor': chatButtonColor, 'borderColor': chatButtonColor }} disabled={continueButtonDisable} onClick={event => setNameAndInitiateChat(nameUser, idUser)}>Continue</button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default PreChatForm;
