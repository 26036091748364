import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Mutex, withTimeout } from "async-mutex";
import "./Chat.css";
import { getArcConfig } from "../../api/ConfigAPI.js";
import { AzureAPI } from "../../api/AzureAPI.js";
import {
  parseParentMessage,
  isJson,
  extractAliasFromEmail,
  uuidv4,
  convertLink,
  downloadImage,
  playAudio,
  getMessage,
  checkForTestScenario,
  inputContainerDefaultParameters,
  scrollDown,
} from "../../common/ChatUtil.js";
import { logger } from "../../common/Logger";
import {
  initiateChatMethod,
  uploadChatLogs,
  chatTransfer,
  getConnectAttributes,
  scrubMessage,
  isAppVersionLatest,
} from "../../api/ChatAPI.js";
import { AmazonConnectParticipantAPI } from "../../api/AmazonConnectParticipantAPI";
import {
  ConnectAccessDeniedError,
  UnKnownError,
  NetworkingError,
  ChatWidgetVersionMismatchError,
  AzureAuthenticationLoginInprogressError,
  SendMessageUnexpectedError,
} from "../../common/CustomError";
import InfoBar from "../InfoBar/InfoBar.js";
import MessageList from "../MessageList/MessageList.js";
import InputContainer from "../InputContainer/InputContainer.js";
import Loader from "../Loader/Loader.js";
import PreChatForm from "../PreChatForm/PreChatForm.js";
import ErrorPage from "../Error/ErrorPage";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { graphConfig, privateChatApi } from "../../authConfig";
import { useTranslation } from "react-i18next";
import { printChatUtility } from "../../common/printChatUtility";
import moment from "moment";

//--------------------------------------------------------------------//
// All Variables that are set one time at the load of the chat widget
//--------------------------------------------------------------------//
let amazonConnectParticipantAPI;
let clientLock = new Mutex();
let notificationImgURL;
let language = "";
let enableTextArea = "false";

//--------------------------------------------------------------------//
// All Transient Variables and does not require reset at the end of chat.
//--------------------------------------------------------------------//
let unreadMessageCount = 0;
var messageToScrub = "";
let interactiveMessageId;
let currentContactId = ""; // current ContactID initially same as config.customerContactID(or initialContactId) but value will change as the contact flow transfer to another contact flow
let onTyping = false;

//--------------------------------------------------------------------//
// All Chat Session Variables that are specific to the current chat
//          conversation and need to be reset when chat ended
//--------------------------------------------------------------------//
var messageListToDisplay = [];
let title = "";
let subtitle = undefined;
let chatWidgetOpened = false;
let soundOff = false;
let forceInitiate = "false";
let isChatStarted = false; // this flag prevents the multiple execution of action (i.e initiateChat) associated with opening chatWidget.

// readonly mode variables
let isNetworkError = false;
let restartFlag = false;
let isConnectParticipantTokenInvalid = false;

// chat status
let isChatConnected = false;
let isAgentConnected = false;

// input contatiner related variables
let placeholderMessage = "";
let isinputBarEnabled = false;
let isDeliveredExists = "";
let isReadExists = "";
let messageScrubbed = true;

//--------------------------------------------------------------------//
// All contastant Variables:
//--------------------------------------------------------------------//
//--------------------------------------------------------------------//
//NOTE: do not change const to let for config.
//--------------------------------------------------------------------//
const config = {
  ChatId: "",
  ConfigKey: "",
  ChatApiURL: "",
  InstanceId: "",
  ContactFlowId: "",
  ChatApiKey: "",
  RegionName: "",
  NameSpace: "",
  isConfigLoaded: false,
  oktaAccessToken: "",
  customerFacing: "true",
  claimsId: "",
  chatSource: "",
  customerLoginUrl: "",
  customerContactID: "",
  sessionID: "",
  userIdentifier: "",
  userName: "",
  chatDetails: {},
  pageOrigin: "",
};
const imageSrc = "image/notificationSmall.png";

//--------------------------------------------------------------------//
// Chat Widget component Starts Here:
//--------------------------------------------------------------------//
const Chat = ({ initialConfig }) => {
  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(failureDivRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(failureDivRef);
      }
    };
  });

  const { t, i18n } = useTranslation();
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  const [renderOfChatPane, nextRenderOfChatPane] = useState({
    preChatForm: undefined, //{name} // missing username for customer facing
    errorPane: undefined, //{errorMessageHeader, errorMessage, retryAction, linkAction, actionName} // group1: display error page
    loaderPane: { loaderMessage: t("chatConfigLoading") }, // {loaderMessage} group2: display Loader message
    closeChatDialogBox: undefined, // {endChatPopUp} group3: display close dialog box
    messagePane: undefined, // {messagePaneReady} group4: display messageList
  });

  const divRef = useRef(null);
  const mainDivRef = useRef(null);
  const formInputRef = useRef(null);
  const failureDivRef = useRef(null);

  // Adding Azure AD Authorization for Internal users
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  //------------------------------------------------------------------------------------------
  // 1st useEffect - will trigger once when the chat widget is loaded in the browser.
  //    happens during the load of the chat button
  //Executes at onload.
  //Register listeners for:
  //  -  "message" event handler to receive message from parent of iframe
  //          Sends a message to parent of iframe to indicate iframe is loaded.
  //  -  "beforeunload" event handler to disconnect seession when script is unloaded
  useEffect(() => {
    console.log(
      "CHATLOG : on 1. useEffect - On load of this chat widget js initialConfig: ",
      initialConfig
    );

    notifyParent("AppLoaded-true");

    //below condition is only for automated testing.
    if (initialConfig !== undefined && initialConfig !== null) {
      console.log(
        "1. useEffect - inside the if check for initialConfig: ",
        initialConfig
      );
      config.ConfigKey = initialConfig.ConfigKey;
      language = initialConfig.language;
      config.userName = initialConfig.customerName;

      config.userIdentifier = initialConfig.customerIdentifier;

      config.customerFacing =
        initialConfig.customerFacing === undefined
          ? config.customerFacing
          : config.customerFacing;
      if (
        config.customerFacing === "true" /*|| config.chatSource === "ECRM"*/
      ) {
        nextRenderOfChatPane({
          from: "01",
          loaderPane: { loaderMessage: t("loadingChat") },
        });
      }
      config.additionalAttributes = initialConfig.additionalAttributes;
      config.claimsId = initialConfig.claimsId;
    }
    if (!notificationImgURL) {
      downloadImage(imageSrc).then((sourceImageURL) => {
        notificationImgURL = sourceImageURL;
      });
    }

    amazonConnectParticipantAPI = new AmazonConnectParticipantAPI({
      eventHandler: {
        onWsConnectionNetworkError: onWsConnectionNetworkErrorEventHandler,
        onConnectionEstablished: onWsConnectionEstablishedEventHandler,
        onConnectionBroken: onWsConnectionBrokenEventHandler,
        onConnectionLost: onWsConnectionLostEventHandler,
        onEnded: onWsSessionEndedEventHandler,
        onMessage: onWsMessageEventHandler,
        onTyping: onWsTypingEventHandler,
        onDeliveredReceipt: onWsDeliveredReceipt,
        onReadReceipt: onWsReadReceipt,
      },
    });

    window.addEventListener("beforeunload", handleWindowBeforeUnloadEvent);
    console.log("1. Added new beforeunload listener");
    window.addEventListener("message", handleWindowParentMessageEvent);
    console.log("1. Added new message listener");

    function handleWindowClick() {
      console.log("window click event");
      if (config.azureApi) config.azureApi.focusMFAModal();
    }
    window.addEventListener("click", handleWindowClick, true);

    logger.setSessionLogLevel();
    logger.getSessionLogLevel();

    return () => {
      console.log("1. Removing Old Handler message listener");
      window.removeEventListener("message", handleWindowParentMessageEvent);
      window.removeEventListener("click", handleWindowClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //------------------------------------------------------------------------------------------
  // This use effect is use to Azure MFA, when the MFA window is on it will display the erroMessage
  // below and once the MFA window is closed it will clear error on the chat
  useEffect(() => {
    console.log("AzureAPI: useffect:", {
      isConfigLoaded: config.isConfigLoaded,
      customerFacing: config.customerFacing,
      inProgress,
      isAuthenticated,
    });

    if (config.isConfigLoaded && config.customerFacing === "false") {
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#table-of-events
      if (inProgress === "ssoSilent") {
        //other  values: startup, handleRedirect
        nextRenderOfChatPane({
          from: "02",
          loaderPane: {
            loaderMessage: "User verification is in progress...",
          },
        });
      }
      if (inProgress === "login") {
        if (!isChatConnected) {
          nextRenderOfChatPane({
            from: "02",
            loaderPane: {
              loaderMessage: "User verification is in progress...",
            },
          });
        } else {
          nextRenderOfChatPane({
            errorPane: {
              errorCode: "E001",
              error: new AzureAuthenticationLoginInprogressError(),
            },
          });
        }
      }

      //once successfully completed MFA authentication clear  renderOfChatPane
      if (inProgress === "none" && isAuthenticated) {
        if (!isChatConnected) {
          nextRenderOfChatPane({
            from: "04a",
            loaderPane: { loaderMessage: t("chatLoader") },
          });
        } else if (renderOfChatPane.errorPane?.errorMessage) {
          nextRenderOfChatPane({
            from: "04b",
            messagePane: { messagePaneReady: true },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  const setAnalytics = () => {
    if (config.ConfigKey) {
      const analyticsScript = document.getElementById("analyticsID");
      if (analyticsScript) {
        analyticsScript.remove();
      }
      var script = document.createElement("script");
      script.crossOrigin = "anonymous";
      let srcVal = "/js/analytics.js?configKey=" + config.ConfigKey;
      if (srcVal.match(/^[a-zA-Z0-9=_.?"\/]*$/) !== null) {
        script.src = srcVal;
      }
      script.type = "text/javascript";
      script.id = "analyticsID";
      script.setAttribute("data-test", "test");
      document.body.appendChild(script);
      console.log(
        "UseEffect Check CustomerContactId",
        config.customerContactID
      );
    }
  };
  //Initiate AdobeAnalytics
  const initiateAdobeAnalytics = () => {
    //var loaded = document.querySelector('script[src="https://static1.st8fm.com/en_US/applications/b2c/js/lib/launchtms_min.js"]') ;
    var id = "analyticsScriptId";
    if (document.getElementById(id) === null) {
      var analyticsScript = document.createElement("script");
      analyticsScript.crossOrigin = "anonymous";
      analyticsScript.src =
        "https://static1.st8fm.com/en_US/applications/b2c/js/lib/launchtms_min.js";
      analyticsScript.type = "text/javascript";
      analyticsScript.id = id;
      document.head.appendChild(analyticsScript);
      console.log("Adobe analytics script loaded");
    } else {
      setTimeout(function () {
        document.dispatchEvent(
          new CustomEvent("chatOpenEventAA", {
            detail: { status: "OPEN" },
          })
        );
      }, 500);
    }
  };

  const handleWindowParentMessageEvent = async (event) => {
    console.log("CHATLOG : on handleParentMessageEvent", event.data);
    let msg = event.data;
    if (typeof msg === "string") {
      if (msg.includes("cwConfiguration")) {
        restartFlag = false;
        messageListToDisplay = [];
        let cwConfiguration = parseParentMessage(msg);
        config.ConfigKey = cwConfiguration.configKey;
        config.ChatId = cwConfiguration.chatId;
        language = cwConfiguration.language;
        config.claimsId = cwConfiguration.claimsId;
        config.chatSource = cwConfiguration.chatSource;
        const parentUrl = cwConfiguration.parentUrl.split("?");
        config.pageOrigin = parentUrl[0];
        if (cwConfiguration.customerFacing === "true") {
          config.customerLoginUrl =
            process.env.REACT_APP_LOGIN_LINK + "?goto=" + parentUrl[0];

          config.userName = cwConfiguration.customerName;
          config.userIdentifier = cwConfiguration.customerIdentifier;
          config.customerFacing = cwConfiguration.customerFacing;
        } else {
          config.customerFacing = "false";
        }

        config.additionalAttributes = cwConfiguration.additionalAttributes;
        enableTextArea = cwConfiguration.enableTextArea;

        if (cwConfiguration.customerFacing === "true") {
          config.oktaAccessToken = cwConfiguration.oktaAccessToken;
          console.log("cwConfiguration", cwConfiguration);
        }
        if (msg.includes("cwConfigurationOnClick")) {
          notifyParent("ConfigLoadedAfterClick-true");
        } else {
          notifyParent("ConfigLoaded-true");
        }
        sessionStorage.setItem("userId", config.userIdentifier); // use by DYNATRACE_RUM_AGENT

        sessionStorage.setItem("configKey", config.ConfigKey); // use by DYNATRACE_RUM_AGENT
      } else if (msg.includes("chatWindowOpened")) {
        // condition to load the config on button click and not on a minimize
        if (!isChatStarted) {
          isChatStarted = true;

          clientLock = withTimeout(new Mutex(), 5000);
          logger.debug("***document cookie before", document.cookie);
          const isVersionLatest = await isAppVersionLatest();
          console.log("***** isVersionLatest: " + isVersionLatest);
          if (!isVersionLatest) {
            nextRenderOfChatPane({
              errorPane: {
                errorCode: "E002",
                error: new ChatWidgetVersionMismatchError("Version Mismatch"),
                retryAction: refreshChat,
              },
            });
            return false;
          }
          logger.debug("***document cookie after", document.cookie);
          await retryChat();
        }
        chatWidgetOpened = true;
        mainDivRef.current.focus();

        if (
          isChatConnected &&
          messageListToDisplay.length &&
          (document.querySelector(".messageListContainer") ||
            document.querySelector(".chat-loader"))
        ) {
          nextRenderOfChatPane({
            from: "29",
            messagePane: { messagePaneReady: true },
          });
        }
      } else if (msg.includes("cwUpdatedAdditionalAttributes-")) {
        let cwUpdatedAdditionalAttributes = JSON.parse(
          msg.replace("cwUpdatedAdditionalAttributes-", "")
        );
        config.additionalAttributes = {
          ...config.additionalAttributes,
          ...cwUpdatedAdditionalAttributes,
        };
      } else if (msg.includes("callScrubbingService#@")) {
        let messages = event.data.split("#@");
        config.oktaAccessToken = messages[1];
        scrubMessageFromInputContainer(messageToScrub);
      }
      resetUnreadNotif();
    }
  };

  // used when customerFacing = false and chatSource !== ECRM to allow the chat to disconnect when changing pages/tabs
  const handleWindowBeforeUnloadEvent = (event) => {
    logger.info("handleWindowBeforeUnloadEvent triggered with event", event);
    const activeElement = event.target?.activeElement;
    uploadChatLogs(config, {
      eventStr: "onHandleWindowBeforeUnloadEvent",
      event: JSON.stringify(event),
      activeElement: JSON.stringify(activeElement),
    });
    logger.info(
      "onbeforeunload",
      activeElement,
      activeElement?.href,
      activeElement?.protocol
    );

    // document.activeElement.protocol === "mailto:";
    // fix for interactive local development
    // when running local do not do disconnect so the websock is not disconnect everytime the widget is reloaded
    // if (process.env.REACT_APP_VERSION === "local") return;

    if (
      activeElement?.protocol !== "mailto:" &&
      activeElement?.protocol !== "tel:" &&
      amazonConnectParticipantAPI &&
      config.customerFacing === "false" &&
      config.chatSource !== "ECRM"
    ) {
      if (amazonConnectParticipantAPI.disconnect()) {
        var x = 200;
        var a = new Date().getTime() + x;
        //add delay of 200 ms
        while (new Date().getTime() < a) {
          if (event.returnValue) delete event["returnValue"];
        }
      }
    }
  };

  //Call API to initiate Chat. Returns response with participant token.
  const initiateChat = async () => {
    console.log("initiateChat call");
    if (config.customerFacing === "false" && config.chatSource !== "ECRM") {
      forceInitiate = "true";
    }

    try {
      const response = await initiateChatMethod(config, forceInitiate);
      // Setting force as false on the getting the response
      forceInitiate = "false";
      config.userIdentifier = response.body?.data?.username;
      console.log(
        "previousContactId:" + response.body?.data?.previousContactId
      );
      console.log("nextContactId: " + response.body.data.nextContactId);
      config.chatDetails = response.body.data.startChatResult;
      config.customerContactID = config.chatDetails.ContactId;
      currentContactId = config.customerContactID;
      console.log("customerContactID: " + config.customerContactID);
    } catch (error) {
      nextRenderOfChatPane({
        errorPane: { errorCode: "E003", error, retryAction: initiateChat },
      });
      notifyParent("Connected-false");
      return;
    }

    try {
      await amazonConnectParticipantAPI.connect(config);
      config.sessionID = amazonConnectParticipantAPI.session.sessionID;
      sessionStorage.setItem("initialContactId", config.chatDetails.ContactId); // use by DYNATRACE_RUM_AGENT
      sessionStorage.setItem("connectSessionId", config.sessionID); // use by DYNATRACE_RUM_AGENT
      notifyParent("Connected-true");
    } catch (error) {
      if (!onWsConnectionNetworkErrorEventHandler(error.toString())) {
        isConnectParticipantTokenInvalid =
          error instanceof ConnectAccessDeniedError;
        nextRenderOfChatPane({
          errorPane: { errorCode: "E004", error, retryAction: retryChat },
        });
      }
      notifyParent("Connected-false");
    }
  };

  const onWsConnectionEstablishedEventHandler = async (event) => {
    uploadChatLogs(config, {
      eventStr: "onConnectionEstablished",
      event: JSON.stringify(event),
      isChatStarted,
    });

    if (isNetworkError) {
      console.log("onConnectionEstablished connection is success!!! ");
      isNetworkError = false;
    } else {
      nextRenderOfChatPane({
        from: "12",
        loaderPane: { loaderMessage: t("chatLoader") },
      });
    }

    if (event.connectCalled && event.connectSuccess) {
      logger.info("Triggered onConnectionEstablished to skiped.", event);
      return;
    } else {
      logger.info("Triggered onConnectionEstablished to process.", event);
    }

    //Logic explanation in comments
    //1st time chatter: previousContactIdValue = "INITIAL_CHAT" (dont go to below if)..... Persist this 1st timer: previousContactIdValue = "INITIAL_CHAT" (go to if)
    //End/new Chat: previousContactIdValue = "hkjdhfkjhf" (dont go to if) ..... Persist this previousContactIdValue = "hkjdhfkjhf" (go to if)
    //calling native transcript api
    try {
      const response = await amazonConnectParticipantAPI.getTranscript();
      const { Transcript } = response;

      isChatConnected = true;
      notifyParent("ConnectionEstablished-true");
      console.log("Transcript size:", {
        connectTranscriptSize: Transcript.length,
        messageListToDisplaySize: messageListToDisplay.length,
      });
      // reset messageListToDisplay only when Transcript.length is 0.  Do not want to reset when we are persisting the chat
      messageListToDisplay =
        Transcript.length === 0 ? [] : messageListToDisplay;

      //Below if keeps the loader for 'Loading Chat' on when transcript comes back empty
      if (Transcript.length !== 0) {
        isinputBarEnabled = true;
        let addMessageObjList = [];

        for (let message of Transcript) {
          var messageObj = onMessageFunc(
            {
              data: message,
              chatDetails: { contactId: response.InitialContactId },
            },
            message.AbsoluteTime,
            isNetworkError ? true : false
          );
          addMessageObjList.push(...messageObj);
        }
        if (addMessageObjList && addMessageObjList.length > 0) {
          addMessage(addMessageObjList);
        }
        //update delivered read display values for UI after persist
        if (isDeliveredExists !== "") {
          findMessageAndChangeStatus(isDeliveredExists, "Delivered");
        }
        if (isReadExists !== "") {
          findMessageAndChangeStatus(isReadExists, "Read");
        }
      }
    } catch (error) {
      if (!onWsConnectionNetworkErrorEventHandler(error.toString())) {
        isConnectParticipantTokenInvalid =
          error instanceof ConnectAccessDeniedError;
        if (
          isConnectParticipantTokenInvalid &&
          messageListToDisplay.length > 0
        ) {
          nextRenderOfChatPane({
            from: "M004",
            messagePane: { messagePaneReady: true },
          });
        } else {
          nextRenderOfChatPane({
            errorPane: { errorCode: "E005", error, retryAction: retryChat },
          });
        }
      }
    }
  };

  const onWsConnectionNetworkErrorEventHandler = (error) => {
    if (
      !isNetworkError &&
      (error.includes("Network Failure") || error.includes("Network offline"))
    ) {
      isNetworkError = true;
      nextRenderOfChatPane({
        from: "09",
        messagePane: { messagePaneReady: true },
      });
      return true;
    } else return false;
  };

  const onWsSessionEndedEventHandler = (event) => {
    logger.info("triggered the onWsSessionEndedEventHandler ", {
      event,
      customerContactID: config.customerContactID,
    });

    //isChatConnected is set to false when user hit the close x, it calls the endChatComplete where it set it to false
    // basically, we do not want the endChatComplete to be called from here when the user ended the chat.
    if (
      isChatConnected &&
      config.customerContactID === event?.chatDetails.initialContactId // making sure that one we are ending the chat is for current customerContactID
    ) {
      uploadChatLogs(config, {
        eventStr: "onEnded-AGENT",
        event,
      });
      endChatComplete(false, true);
    }
  };

  const onWsConnectionLostEventHandler = (event) => {
    logger.info("triggered the onConnectionLost ", event);
    uploadChatLogs(config, {
      eventStr: "onConnectionLost",
      event,
    });
  };

  const onWsConnectionBrokenEventHandler = (event) => {
    logger.info("triggered the onWsConnectionBrokenEventHandler ", event);
    uploadChatLogs(config, {
      eventStr: "onConnectionBroken",
      event,
    });
  };

  const onWsMessageEventHandler = async (message) => {
    var messageObjList = onMessageFunc(
      message,
      message.data.AbsoluteTime,
      true
    );
    if (messageObjList.length > 0) {
      let release = await clientLock.acquire();
      try {
        await addMessage(messageObjList, true);
      } catch (e) {
        console.log("Exception during addMessage processing", e);
      } finally {
        release();
      }
    }
  };

  const onWsTypingEventHandler = (typingEvent) => {
    if (typingEvent.data.ParticipantRole === "AGENT") {
      onTyping = true;
      if (
        document.querySelector(".messageListContainer") ||
        document.querySelector(".chat-loader")
      ) {
        nextRenderOfChatPane({
          from: "13",
          messagePane: { messagePaneReady: true },
        });
      }
    }
  };

  const onWsDeliveredReceipt = (event) => {
    // console.log(
    //   "***Received Delivered receipt:",
    //   event.data,
    //   messageListToDisplay
    // );
    updateReadDeliveredStatus(event.data, "Delivered");
    nextRenderOfChatPane({
      from: "36",
      messagePane: {
        ...renderOfChatPane.messagePane,
        messageListReady: true,
      },
    });
  };

  const onWsReadReceipt = (event) => {
    //console.log("***Received Read receipt:", event.data, messageListToDisplay);
    updateReadDeliveredStatus(event.data, "Read");
    nextRenderOfChatPane({
      from: "37",
      messagePane: {
        ...renderOfChatPane.messagePane,
        messageListReady: true,
      },
    });
  };

  const updateReadDeliveredStatus = (data, valueToUpdate) => {
    //isDeliveredExists stores the message id of the last delivered message
    //isReadExists stores the message id of the last read message
    //when a Read WS (valueToUpdate variable holds that incoming WS status) comes in, clear out isDeliveredExists because now that message needs to be updated to read status
    if (valueToUpdate === "Read") {
      isDeliveredExists = "";
      //if isReadExists is not empty, it means one of the above messages have been read, then set that message id to "None" so that 'Read' displayed on UI screen disappears
      if (isReadExists !== "") {
        findMessageAndChangeStatus(isReadExists, "None");
      }
      //update isReadExists to this new message id.
      isReadExists = "a" + data.MessageMetadata.MessageId;
    }
    //if isDeliveredExists is not empty, it means one of the above messages have been delivered, then set that message id to "None" so that 'Delivered' displayed on UI screen disappears
    if (isDeliveredExists !== "") {
      findMessageAndChangeStatus(isDeliveredExists, "None");
    }
    //when a Delivered WS (valueToUpdate variable holds that incoming WS status) comes in, update isDeliveredExists to this new message id.
    if (valueToUpdate === "Delivered") {
      isDeliveredExists = "a" + data.MessageMetadata.MessageId;
    }
    //update the message's id to Read or Delivered so that it can be displayed on the UI screen
    findMessageAndChangeStatus(
      "a" + data.MessageMetadata.MessageId,
      valueToUpdate
    );
  };

  //updates readDeliveryStatus status to Read, Delivered or None for the message id sent as 'compareValue' variable
  const findMessageAndChangeStatus = (compareValue, newStatus) => {
    const messageToBeUpdated = messageListToDisplay.find(
      (message) => message.id === compareValue
    );
    if (messageToBeUpdated) {
      messageToBeUpdated.readDeliveryStatus = newStatus;
    }
  };

  const handleTransferEvent = async (contactId) => {
    try {
      await chatTransfer(config, contactId);
      currentContactId = contactId;
    } catch (error) {
      if (!restartFlag && !isNetworkError)
        nextRenderOfChatPane({
          errorPane: {
            errorCode: "E006",
            error,
            retryAction: () => handleTransferEvent(contactId),
          },
        });
    }
  };

  const onMessageFunc = (message, timestamp, fromWebSocket = false) => {
    isNetworkError = false;
    let addMessageObjList = [];
    if (!messageScrubbed) {
      messageToScrub = "";
    }

    const messageObj = {
      contactId: message?.data?.ContactId, //
      id: "a" + message?.data?.Id,
      userType: message?.data?.ParticipantRole, //
      messageUserName: message?.data?.DisplayName, //
      messageText: message?.data?.Content, //
      messageTime: (timestamp && moment(timestamp)) || moment(), //
      contentType: message?.data?.ContentType,
    };

    //makes links clickable when sent by Rep
    switch (message.data.Type) {
      case "EVENT":
        if (
          fromWebSocket &&
          messageObj.contentType !==
            "application/vnd.amazonaws.connect.event.participant.left" &&
          messageObj.contactId &&
          messageObj.contactId !== currentContactId
        ) {
          handleTransferEvent(messageObj.contactId); //removed sso
        }

        if (
          messageObj.userType === "AGENT" &&
          messageObj.contentType ===
            "application/vnd.amazonaws.connect.event.participant.joined"
        ) {
          addMessageObjList = [
            {
              ...messageObj,
              messageText: `<b> ${messageObj.messageUserName} joined the chat.</b> <br/><br/>To effectively utilize our available resources, if you are holding on the phones, you may disconnect that call once connected with a Chat representative. Chats are timed live sessions. Once connected, please monitor the conversation for its entire duration to ensure a timely resolution.`,
              userType: "EVENT",
            },
          ];
        }
        break;

      case "MESSAGE":
        //authenticated customer facing
        if (config.customerFacing === "true" && fromWebSocket) {
          const eventLog = {
            eventStr: "onMessageFunc",
            messageID: messageObj.id,
            ParticipantRole: messageObj.userType,
            ContactId: messageObj.contactId,
            messageSize: messageObj.messageText
              ? messageObj.messageText.length
              : 0,
            message: messageObj.messageText
              ? messageObj.messageText.substr(0, 3)
              : "Undefined",
          };
          uploadChatLogs(config, eventLog);
        }

        switch (messageObj.userType) {
          case "CUSTOMER":
            //Start of logic for Transcript
            const userMessage = messageListToDisplay.find(({ id }) =>
              id.includes(message.data.Id)
            );
            if (!userMessage) {
              //   Receipts[
              //     {
              //         "DeliveredTimestamp": "2024-04-30T14:22:14.976Z",
              //         "ReadTimestamp": "2024-04-30T14:22:14.976Z",
              //         "RecipientParticipantId": "c1425d36-d3bd-42c5-8e4d-c8094c5f69d0"
              //     }
              // ]

              //All customer messages have a MessageMetadata but the BOT replied customer messages have Receipts as [] hence we need to check below

              if (message.data.MessageMetadata?.Receipts.length > 0) {
                //ReadTimestamp from transcript only shows up if that message has been Read else it is null, so if that exists we do not need to check DeliveredTimestamp and set it to 'None'
                if (
                  message.data.MessageMetadata?.Receipts[0]?.ReadTimestamp !==
                  null
                ) {
                  isDeliveredExists = ""; //remove any previous messages with delivered for transcript
                  isReadExists = "a" + message.data.MessageMetadata.MessageId; //set isReadExists to this message's id.
                }
                //else means DeliveredTimestamp is only available, no ReadTimestamp available, hence we update isDeliveredExists to that message's id
                else if (
                  message.data.MessageMetadata?.Receipts[0]
                    ?.DeliveredTimestamp !== null
                ) {
                  isDeliveredExists =
                    "a" + message.data.MessageMetadata.MessageId;
                }
              }
              //Adding the readDeliveryStatus variable to the messageObj with "None" status.
              //It will get set to appropriate value in onWsConnectionEstablishedEventHandler based on the above isDeliveredExists and isReadExists values
              addMessageObjList = [
                { ...messageObj, readDeliveryStatus: "None" },
              ];
              //End of logic for Transcript
            }

            break;

          case "AGENT":
            addMessageObjList = [
              {
                ...messageObj,
                messageText: convertLink(messageObj.messageText),
              },
            ];
            break;

          case "SYSTEM":
            logger.debug(" Bot/System message type", messageObj.contentType);
            const isContentJson =
              messageObj.messageText && isJson(messageObj.messageText);
            messageObj.messageText = isContentJson
              ? JSON.parse(messageObj.messageText)
              : messageObj.messageText;
            switch (messageObj.contentType) {
              // I believe this is not a valid ContentType "application/vnd.amazonaws.connect.message.interactive".
              // the valid ContentType is "application/vnd.amazonaws.connect.message.interactive.response".
              // The list of valid ContentType is listed on
              // https://docs.aws.amazon.com/connect/latest/APIReference/API_ChatMessage.html
              // This can not be tested at this time because we could not find the flow that produces this message.
              // Please remove this in April 2024 after verifying that this case is not showing in the logs.
              case "application/vnd.amazonaws.connect.message.interactive":
                interactiveMessageId = messageObj.id;
                messageObj.messageText.isInteractiveMessageClicked = false;
                addMessageObjList = [{ ...messageObj }];

                const eventLog = {
                  eventStr: "onMessageFuncInteractiveContentType",
                  messageObj,
                };
                uploadChatLogs(config, eventLog);
                break;

              default:
                switch (true) {
                  case !isContentJson: //"plain text"
                    addMessageObjList = [{ ...messageObj }];
                    break;

                  case messageObj.messageText?.templateType === "ListPicker":
                    interactiveMessageId = messageObj.id;
                    messageObj.messageText.isInteractiveMessageClicked = false;
                    addMessageObjList = [{ ...messageObj }];
                    break;

                  default: //quick reply
                    messageObj.messageText.forEach(
                      (botMessage, index, array) => {
                        const addMessageObj = {
                          ...messageObj,
                          id:
                            messageObj.id +
                            (array.length === 1 ? "" : "-" + index),
                          messageText: botMessage,
                        };
                        // split quick replies into a seperate addMessage call for delay processing
                        if (
                          index + 1 === array.length - 1 &&
                          array[array.length - 1].type === "quickReplies" // ContentType = "quick reply"
                        ) {
                          // is the last message in the list a quickrepl
                          addMessageObjList.push(addMessageObj, {
                            ...addMessageObj,
                            id: addMessageObj.id + "qr",
                            messageText: array[index + 1],
                          }); //add the last two message together with the same id
                        } else if (
                          array.length === 1 ||
                          array[index].type !== "quickReplies"
                        ) {
                          addMessageObjList.push(addMessageObj); // ContentType = regular text message
                        }
                      }
                    );
                }
                break;
            }
            break;

          default:
            logger.warn(
              "Unexpected message.data.ParticipantRole found! ",
              message
            );
            break;
        }
        break;

      default:
        logger.warn("Unexpected message.data.Type! ", message);
        break;
    }
    notifyParent("chatActive");
    return addMessageObjList;
  };

  async function getRepAlias(repAlias, contactId) {
    try {
      if (repAlias) {
        return repAlias;
      } else {
        const response = await getConnectAttributes(config, contactId, [
          "agent_username",
        ]);
        return extractAliasFromEmail(response?.body?.agent_username)?.alias;
      }
    } catch (error) {
      if (!restartFlag && !isNetworkError) {
        if (!(error instanceof UnKnownError))
          nextRenderOfChatPane({
            errorPane: {
              errorCode: "E007",
              error,
              retryAction: () => getRepAlias(repAlias, contactId),
            },
          });
        throw error;
      }
    }
  }

  const resetUnreadNotif = () => {
    scrollDown();
    unreadMessageCount = 0;
    notifyParent({
      message: "offTileMessage",
      value: 0,
      chatWindowOpned: chatWidgetOpened,
    });
  };

  const setNotificationOnTabInactive = () => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        resetUnreadNotif();
      }
    });
    if (document.hidden || !chatWidgetOpened) {
      unreadMessageCount++;
      notifyParent({
        message: "onTileMessage",
        value: unreadMessageCount,
        chatWindowOpned: chatWidgetOpened,
      });
    }
  };

  const addMessage = async (addMessageObjList, fromWebSocket = false) => {
    if (!addMessageObjList || addMessageObjList.length === 0) {
      return;
    }

    // transform the addMessageObjList to newMessageList object
    const newMessageList = addMessageObjList.map((addMessageObj) => {
      return {
        ...addMessageObj,
        isAgent: addMessageObj.userType === "AGENT",
        isSystem: addMessageObj.userType === "SYSTEM",
        isEvent: addMessageObj.userType === "EVENT",
        isCustomer: addMessageObj.userType === "CUSTOMER",
        displayUserName: undefined,
        repAlias: undefined,
        displayTimeStamp: Date.now(),
        readDeliveryStatus: addMessageObj.readDeliveryStatus,
      };
    });

    console.log("before adding newMessageList to messageListToDisplay:", {
      fromWebSocket,
      newMessageList,
      messageListToDisplay,
    });

    // if message.id does not exist in messageListToDisplay, then find the index of where to insert it in the messageListToDisplay based in the accending order by messageTime
    let lastMessage =
      messageListToDisplay.length === 0
        ? undefined
        : messageListToDisplay[messageListToDisplay.length - 1];

    newMessageList.forEach((newMessage, i) => {
      let insertIndex = -1;
      let found = messageListToDisplay.some((msg) => msg.id === newMessage.id);

      let aTempMessageFound = false;
      if (!found && newMessage.isCustomer) {
        messageListToDisplay.forEach((msg) => {
          if (!aTempMessageFound && msg.id.startsWith("aTempMessage")) {
            msg.id = newMessage.id;
            aTempMessageFound = true;
            msg.displayUserName = newMessage.displayUserName;
            msg.messageUserName = newMessage.messageUserName;
            msg.contactId = newMessage.contactId;
            msg.messageTime = newMessage.messageTime;
            msg.contentType = newMessage.contentType;
          }
        });
      }

      checkForTestScenario(
        addMessageObjList[0].messageText,
        "trigger-addmessage-exception",
        fromWebSocket,
        new Error("Error while adding message to messageListToDisplay.")
      );

      found = messageListToDisplay.some((msg) => msg.id === newMessage.id);
      if (!found) {
        insertIndex = messageListToDisplay.findIndex((msg) =>
          moment(msg.messageTime).isAfter(moment(newMessage.messageTime))
        );
        //Add delay to system messages that are received back to back.
        if (
          messageListToDisplay.length > 0 &&
          fromWebSocket &&
          insertIndex === -1
        ) {
          if (newMessage.messageText?.type === "quickReplies" && lastMessage) {
            newMessage.delayMessage = lastMessage.delayMessage;
            newMessage.displayTimeStamp = lastMessage.displayTimeStamp;
          } else {
            const delayInMilliSec =
              750 -
              (newMessage.displayTimeStamp - lastMessage.displayTimeStamp);
            if (
              lastMessage.isSystem &&
              newMessage.isSystem &&
              delayInMilliSec > 0 &&
              newMessage.messageTime >= lastMessage.messageTime
            ) {
              newMessage.delayMessage = delayInMilliSec;
              newMessage.displayTimeStamp =
                newMessage.displayTimeStamp + delayInMilliSec;
              setTimeout(() => {
                playAudio(false, soundOff);
                const sameMessageIdList = document.querySelectorAll(
                  "#" + newMessage.id + ",#" + newMessage.id + "qr"
                );
                sameMessageIdList.forEach((element) => {
                  element.hidden = false;
                });
                newMessage.delayMessage = undefined;
                const qrValue = messageListToDisplay.find(
                  (row) => row.id === newMessage.id + "qr"
                );
                if (qrValue) {
                  qrValue.delayMessage = undefined;
                }

                //perform autoscroll
                scrollDown();
              }, delayInMilliSec);
            }
          }
          lastMessage = newMessage;
        }

        // play the chime when message from agent or system is recievedif there is no delay set, otherwise the chime will be play after the delay elapsed.
        if (
          !newMessage.delayMessage &&
          newMessage.messageText?.type !== "quickReplies" &&
          (newMessage.isAgent || newMessage.isSystem) &&
          fromWebSocket
        ) {
          playAudio(false, soundOff);
        }
        // only play the jingle when adding the event to the messageListToDisplay for the first time.
        if (
          newMessage.isEvent &&
          newMessage.messageText.includes("joined the chat")
        ) {
          playAudio(true, soundOff);
          isAgentConnected = true;
          title = newMessage.messageUserName;
        }

        if (
          newMessage.isSystem &&
          newMessage.messageText ===
            "We have experienced a technical error, so you will have to restart your chat. You will  be asked to provide your information again. We apologize for any inconvenience you may encounter."
        ) {
          restartFlag = true;
        }

        if (newMessage.isSystem) {
          isAgentConnected = false;
          title = t("virtualAssistant");
        }

        //Sort the messageListToDisplay by ascending messageTime.
        messageListToDisplay.splice(
          insertIndex === -1 ? messageListToDisplay.length : insertIndex,
          0,
          newMessage
        );
      }
    });

    // Post addMessage to messageListToDisplay processing:
    //  1) add repAlias for internal facing chat
    //  2) add displayUserName property to each message for displaying the UserName & timestamp label in the message list.

    let lastRepJoined;
    let lastUser = "";
    for await (let msg of messageListToDisplay) {
      // Add repAlias for internal facing chat
      if (config.customerFacing === "false") {
        if (msg.isEvent && msg.messageText.includes("joined the chat")) {
          try {
            lastRepJoined = await getRepAlias(msg.repAlias, msg.contactId);
            msg.repAlias = lastRepJoined;
            if (!msg.messageText.includes(msg.repAlias)) {
              msg.messageText = msg.messageText.replace(
                " joined the chat.",
                " (" + msg.repAlias + ") joined the chat."
              );
            }
          } catch (e) {
            console.log("error on getRepAlias", e);
          }
        } else if (msg.isAgent && lastRepJoined) {
          msg.repAlias = lastRepJoined;
        }
      }

      //Add displayUserName property.
      const currentUser = msg.isAgent
        ? "AGENT"
        : msg.isSystem
        ? "SYSTEM"
        : msg.isCustomer
        ? "CUSTOMER"
        : "EVENT" + msg.messageUserName;
      // eslint-disable-next-line eqeqeq
      msg.displayUserName = lastUser != currentUser;
      lastUser = currentUser;
    }
    onTyping = false;

    const inputContainerDefaultParam = inputContainerDefaultParameters({
      messageList: messageListToDisplay,
      enableTextArea:
        config.customerFacing === "true" || enableTextArea === "true",
    });

    placeholderMessage = t(inputContainerDefaultParam.placeholderMessage);
    isinputBarEnabled = inputContainerDefaultParam.isinputBarEnabled;

    // request to render the messageListToDisplay by calling the set state.
    if (
      document.querySelector(".messageListContainer") ||
      document.querySelector(".chat-loader") ||
      // message is from the user last typed
      messageListToDisplay[messageListToDisplay.length - 1].id.includes(
        "TempMessage"
      )
    ) {
      nextRenderOfChatPane({
        from: "15",
        messagePane: { messagePaneReady: true },
      });
    }

    setNotificationOnTabInactive();
    console.log("after adding newMessageList to messageListToDisplay: ", {
      newMessageList,
      messageListToDisplay,
    });
  };

  // sends message to herculesChat.js to get a fresh OKTA accessToken when new message is sent
  const getOktaAndScrub = async (messageEvent) => {
    if (isNetworkError) {
      throw new NetworkingError("There is network error");
    }
    // Type 'test technical error' to simulate a technical error scenario
    // Below if block represents the above comment
    if (checkForTestScenario(messageEvent, "trigger-technical-error", true)) {
      const messageObjList = onMessageFunc(
        {
          data: {
            Type: "MESSAGE",
            DisplayName: "SYSTEM_MESSAGE",
            ParticipantRole: "SYSTEM",
            ParticipantId: "Dummy",
            InitialContactId: currentContactId,
            ContactId: currentContactId,
            ContentType: "application/json",
            Content:
              "We have experienced a technical error, so you will have to restart your chat. You will  be asked to provide your information again. We apologize for any inconvenience you may encounter.",
            AbsoluteTime: Date.now(),
            id: "mocktest" + uuidv4(),
          },
          chatDetails: { contactId: currentContactId },
        },
        undefined,
        true
      );
      addMessage(messageObjList, true);
    } else if (
      config.customerFacing === "false" ||
      config.oktaAccessToken.startsWith("UNAUTHENTICATED")
    ) {
      messageToScrub = messageEvent;
      scrubMessageFromInputContainer(messageEvent);
    } else {
      messageToScrub = messageEvent;
      notifyParent("getOktaAndScrub");
    }
  };

  // receives message from hurculesChat.js to set fresh OKTA accessToken and send messsage for scrubbing
  const scrubMessageFromInputContainer = async (messageContent) => {
    console.log("Entered scrubMessageFromInputContainer()", {
      messageContent,
    });
    //if condition to avoid empty message when pressing enter from keyboard
    if (messageContent.length > 0) {
      let scrubbedMessage = messageContent;
      if (messageContent) {
        try {
          document.getElementById("chatTextBoxId").placeholder =
            t("msgSending");
          const response = await scrubMessage(config, messageContent);
          scrubbedMessage = getMessage(messageContent, response);
          await sendChatMessageToConnect(scrubbedMessage); //update message in case of error and send the message. Jumping dots will be hidden
          messageScrubbed = false;
        } catch (error) {
          if (!restartFlag && !isNetworkError) {
            // on UnKnownError proceed with unscrub data to send message to connect
            if (error instanceof UnKnownError) {
              nextRenderOfChatPane({
                errorPane: {
                  errorCode: "E008",
                  error: new SendMessageUnexpectedError(error.message),
                  retryAction: async () => {
                    await sendChatMessageToConnect(scrubbedMessage); //update message in case of error and send the message. Jumping dots will be hidden
                  },
                },
              });
              messageScrubbed = false;
            } else {
              nextRenderOfChatPane({
                errorPane: {
                  errorCode: "E009",
                  error,
                  retryAction: () =>
                    scrubMessageFromInputContainer(messageContent),
                },
              });
            }
          }
        } finally {
          // if (!restartFlag && !isNetworkError && !errorFound) {
          //   try {
          //     await sendChatMessageToConnect(scrubbedMessage); //update message in case of error and send the message. Jumping dots will be hidden
          //     messageToScrub = "";
          //   } catch (e) {
          //     // question: should we throw an error here? current code is just writting to console.
          //     console.error("from finally error obj: ", {
          //       e,
          //       messageContent,
          //       scrubbedMessage,
          //     });
          //   }
          //   notifyParent("chatActive");
          // } else {
          //   if (document.getElementById("chatTextBoxId")) {
          //     document.getElementById("chatTextBoxId").value = messageToScrub;
          //   }
          // }
          // console.log(
          //   "scrubMessageFromInputContainer - finally restoring inputbox value.",
          //   {
          //     isNetworkError,
          //     messageContent,
          //   }
          // );
          if (document.getElementById("chatTextBoxId")) {
            document.getElementById("chatTextBoxId").placeholder =
              t("typeMessage");
          }
        }
      }
    }
  };

  const sendChatMessageToConnect = async (messageContent) => {
    const userMessageObj = {
      messageText: messageContent,
      userType: "CUSTOMER",
      messageUserName: config.userName,
      messageTime: moment(),
      id: "aTempMessage" + uuidv4(),
    };

    try {
      await addMessage([userMessageObj]);
      await amazonConnectParticipantAPI.sendMessage(messageContent);
    } catch (error) {
      const itemToRemove = messageListToDisplay.findIndex(
        (item) => item.id === userMessageObj.id
      );

      logger.debug(
        "sendChatMessageToConnect error rollback the last message from messagelist",
        {
          itemToRemove,
          userMessageObjId: userMessageObj.id,
          lastMessage: messageListToDisplay[messageListToDisplay.length - 1],
        }
      );
      if (itemToRemove !== -1) {
        if (document.getElementById("chatTextBoxId")) {
          document.getElementById("chatTextBoxId").value =
            messageListToDisplay[itemToRemove].messageText;
          document.getElementById("chatTextBoxId").placeholder =
            placeholderMessage;
        }
        messageListToDisplay.splice(itemToRemove, 1);
      }
      if (onWsConnectionNetworkErrorEventHandler(error.toString())) {
        return;
      } else if (error instanceof ConnectAccessDeniedError) {
        isConnectParticipantTokenInvalid = true;
        nextRenderOfChatPane({
          from: "33",
          messagePane: { messagePaneReady: true },
        });
      } else {
        logger.error("sendChatMessageToConnect error : ", error);
        nextRenderOfChatPane({
          errorPane: {
            errorCode: "E010",
            error: new SendMessageUnexpectedError(error.message),
            retryAction: () => {
              nextRenderOfChatPane({
                from: "34a",
                messagePane: { messagePaneReady: true },
              });
            },
          },
        });
      }
    }

    notifyParent("chatActive");
    // notify parent here
  };

  //Retry Chat when clicked on button in Error page.
  const retryChat = async (event) => {
    logger.info("Entered retryChat()", event);
    // Only execute endChatComplete when the retry button is clicked.
    // retry function is called from intial chat buttom click or from error page or preform page
    // event will be only defined when retry button is clicked from the error page, other places when retry is called, event is undefined
    if (event) {
      event.preventDefault();
      endChatComplete(true); // only execute endChatComplete when the retry button is clicked.
    }

    subtitle =
      config.claimsId !== "null" && config.claimsId !== ""
        ? "Claims ID: " + config.claimsId
        : undefined;

    const logLevel = logger.getSessionLogLevel();
    console.log("chatLogLevel from cookie", logLevel);

    nextRenderOfChatPane({
      from: "19",
      loaderPane: { loaderMessage: t("chatConfigLoading") },
    });
    try {
      const newConfig = await getArcConfig(config);
      Object.keys(newConfig).forEach((key) => {
        config[key] = newConfig[key];
      });
    } catch (error) {
      config.isConfigLoaded = false;
      nextRenderOfChatPane({
        errorPane: {
          errorCode: "E011",
          error,
          retryAction: retryChat,
        },
      });
      return false;
    }

    if (
      config?.isConfigLoaded &&
      config?.customerFacing === "true" &&
      (!config?.userName || config?.userName === "")
    ) {
      nextRenderOfChatPane({ from: "21", preChatForm: { name: undefined } });
      return false;
    } else if (config.isConfigLoaded && config.customerFacing === "false") {
      config.azureApi = config.azureApi
        ? config.azureApi
        : new AzureAPI({
            instance,
            graphScopes: graphConfig.scopes,
            apiScopes: privateChatApi.scopes,
          });
      try {
        const response = await config.azureApi.getUserIdentity();
        console.log(" getUserIdentity:", response);
        config.userIdentifier = response.userIdentifier;
        config.userName = response.userName;
      } catch (error) {
        console.error("catch Error on getUserIdentity()", {
          isAuthenticated,
          error,
          errorString: error.toString(),
        });
        nextRenderOfChatPane({
          errorPane: { errorCode: "E012", error, retryAction: retryChat },
        });
        return false;
      }
    }

    if (
      config.userName?.length &&
      config.userIdentifier?.length &&
      (messageListToDisplay.length === 0 || !isChatConnected)
    ) {
      sessionStorage.setItem("userId", config.userIdentifier); // use by DYNATRACE_RUM_AGENT
      title = t("welcome.label") + ", " + config.userName;
      nextRenderOfChatPane({
        from: "08",
        loaderPane: { loaderMessage: t("chatLoader") },
      });
      setAnalytics();
      initiateAdobeAnalytics();
      initiateChat();
    } else {
      nextRenderOfChatPane({
        errorPane: {
          errorCode: "E013",
          error: new UnKnownError("UnknownError"),
          retryAction: retryChat,
        },
      });
    }

    return false;
  };

  //create a cookie to use, auto open the chat window, clear the cookie again
  const refreshChat = async (event) => {
    notifyParent("refreshAndAutoOpenChat");
    console.log("*** refreshed chat");
    return false;
  };

  // Minimize Chat
  const minimizeChat = (event) => {
    event.preventDefault();
    notifyParent("minimizeChat-true-" + isNetworkError);
    chatWidgetOpened = false;
    notifyParent({ message: "hideChat", value: "hide" });
  };

  //End Chat when user clicks on End Chat button
  const endChat = (event) => {
    event.preventDefault();
    if (
      isConnectParticipantTokenInvalid ||
      restartFlag ||
      (config.customerFacing === "false" &&
        messageListToDisplay.length === 0 &&
        (!isAuthenticated || !isChatConnected))
    ) {
      logger.debug("endChat case1", event);
      endChatComplete();
    } else {
      logger.debug("endChat case2", event);
      if (isChatConnected) {
        nextRenderOfChatPane({
          from: "25",
          closeChatDialogBox: {
            endChatPopUp: true,
          },
        });
      } else {
        logger.debug("endChat case3", event);
        endChatComplete();
      }
      return false;
    }
  };

  const onYesToEndChat = (event) => {
    event.preventDefault();
    endChatComplete();
    return false;
  };

  const onNoToEndChat = (event) => {
    event.preventDefault();
    nextRenderOfChatPane({
      from: "27",
      messagePane: { messagePaneReady: true },
    });
  };

  //Complete the End Chat process. called when End Chat promise completes.
  const endChatComplete = async (
    fromRetry = false,
    fromWSSessionEnd = false
  ) => {
    // document.cookie = "initialChatLoad=;expires=Thu, 01 Jan 1970 00:00:00 GMT";  // this are handle by notifyParent("ChatEnded-true") on endChatComplete
    // document.cookie = "ChatID=;expires=Thu, 01 Jan 1970 00:00:00 GMT";           // this are handle by notifyParent("ChatEnded-true") on endChatComplete

    // close the azure popup authentication window if open
    if (config.azureApi) {
      console.log("AzureAPI: Close MFA popup ");
      config.azureApi.closeMFAModal();
    }
    // re-render the chat widget
    nextRenderOfChatPane({
      from: "28",
      loaderPane: {
        loaderMessage: t("chatEnding"),
      },
    });

    // disconnect chat from the websocket if not yet disconnected
    await amazonConnectParticipantAPI.disconnect(); // make sure that the websocket is closed

    // reset all chat global variables
    forceInitiate =
      fromRetry && !isConnectParticipantTokenInvalid ? "false" : "true";
    messageListToDisplay = [];
    restartFlag = false;
    isChatConnected = false;
    isAgentConnected = false;
    isNetworkError = false;
    isConnectParticipantTokenInvalid = false;
    adjustSound(false);
    title = "";
    isChatStarted = false;

    // notify the embedded chat page that the chat widget is now closing.
    if (!fromRetry) {
      chatWidgetOpened = false;
      notifyParent({ message: "hideChat", value: "hide" });
      notifyParent("ChatEnded-true");
    }
    if (fromWSSessionEnd) {
      notifyParent("Session-ended");
    }
  };

  //Adjust the chime sound off or on
  const adjustSound = (value) => {
    soundOff = value;
  };

  const printChat = (event) => {
    event.preventDefault();
    // apply the language translator before printing
    const transcript = messageListToDisplay.map((message) =>
      message.isSystem
        ? { ...message, messageUserName: t(message.messageUserName) }
        : message
    );
    printChatUtility(transcript, config);
  };

  const sendOnTypingAlert = async (value) => {
    if (initialConfig !== undefined && initialConfig !== null) {
    } else {
      try {
        await amazonConnectParticipantAPI.sendTypingEvent(value);
      } catch (error) {
        if (onWsConnectionNetworkErrorEventHandler(error.toString())) {
          return;
        } else if (error instanceof ConnectAccessDeniedError) {
          isConnectParticipantTokenInvalid = true;
          nextRenderOfChatPane({
            from: "35",
            messagePane: { messagePaneReady: true },
          });
        } else {
          logger.error("sendOnTypingAlert error : ", error);
        }
      }
    }
  };

  // Post a message to parent of iframe - unread count, app loaded etc..
  const notifyParent = (message) => {
    const newMessage = {
      message: message,
      ChatId: config.ChatId,
    };
    // eslint-disable-next-line no-restricted-globals
    if (parent) parent.postMessage(newMessage, "*");
  };

  logger.debug("");
  logger.debug(
    "------------------------On render chat widget ------------------------"
  );
  logger.debug(
    "widget On render:            renderOfChatPane: " +
      JSON.stringify(renderOfChatPane)
  );
  logger.debug("widget On render:                  chatStatus: ", {
    isChatConnected,
    isAgentConnected,
    // isBotConnected,
  });
  logger.debug(
    "widget On render:              isConfigLoaded: " + config.isConfigLoaded
  );
  logger.debug(
    "widget On render:             isAuthenticated: " + isAuthenticated
  );
  logger.debug("widget On render:                  inProgress: " + inProgress);
  logger.debug(
    "widget On render:                    userName: " + config.userName
  );
  logger.debug(
    "widget On render:              userIdentifier: " + config.userIdentifier
  );
  logger.debug(
    "widget On render: messageListToDisplay length: " +
      messageListToDisplay?.length
  );
  logger.debug(
    "widget On render:last on messageListToDisplay: ",
    messageListToDisplay.length === 0
      ? undefined
      : messageListToDisplay[messageListToDisplay.length - 1]
  );

  logger.debug(
    "========================On render chat widget========================"
  );

  //rep chat accepts 1024 characters, Hercules limitation should be upto 1000 chars with 24 buffer characters for the word breakage
  useEffect(() => {
    changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // Using fetchasync

  //--------------------------------------------------------------------//
  // render chat widget:
  //--------------------------------------------------------------------//
  return (
    <div
      id="chatContainerchatContainerId"
      ref={mainDivRef}
      className="chatContainer -oneX"
      role={window.screen.width > 678 ? "" : "dialog"}
      title={window.screen.width > 678 ? "" : "Chat Window Opened"}
    >
      <InfoBar
        title={title}
        subtitle={subtitle}
        minimizeChat={config.chatSource === "ECRM" ? undefined : minimizeChat}
        onClickCloseChatButton={endChat}
      />

      {renderOfChatPane.errorPane && (
        <ErrorPage
          errorCode={renderOfChatPane.errorPane.errorCode}
          error={renderOfChatPane.errorPane.error}
          buttonAction={renderOfChatPane.errorPane.retryAction}
          linkAction={config.customerLoginUrl}
        />
      )}

      {renderOfChatPane.loaderPane && (
        <Loader loaderMessage={renderOfChatPane.loaderPane.loaderMessage} />
      )}

      {renderOfChatPane.preChatForm && (
        <PreChatForm
          userName={config.userName}
          userIdentifier={"userIdentifier"}
          setNameAndInitiateChat={(newNameUser, idUser) => {
            config.userName = newNameUser;
            retryChat();
          }}
          formInputRef={formInputRef}
        />
      )}

      {renderOfChatPane.messagePane && (
        <>
          <div
            className="-oneX-row error-notification"
            hidden={
              !isNetworkError &&
              !restartFlag &&
              !isConnectParticipantTokenInvalid
            }
          >
            {notificationImgURL.match(/^[a-zA-Z0-9:.\-\/]*$/) !== null && (
              <img
                src={notificationImgURL}
                className="-oneX-col-2 error-notification-img"
                aria-label="State Farm Logo"
                alt="NotificationImage"
              />
            )}
            {isNetworkError && (
              <span className="-oneX-col-10 error-notification-txt">
                {t("lostConnection")}
              </span>
            )}
            {restartFlag && (
              <span className="-oneX-col-10 error-notification-txt">
                {t("unexpectedErrMsg3")}
              </span>
            )}
            {isConnectParticipantTokenInvalid && (
              <span className="-oneX-col-10 error-notification-txt">
                {t("unexpectedErrMsg4")}
              </span>
            )}
          </div>
          <MessageList
            messageList={messageListToDisplay}
            agentHasJoinedConversation={isAgentConnected}
            divRef={divRef}
            onTyping={onTyping}
            sendMessage={sendChatMessageToConnect}
            isNetworkError={
              restartFlag || isNetworkError || isConnectParticipantTokenInvalid
            }
          />
          {/* {console.log("ChatStatus", chatStatus)} */}
          <InputContainer
            onTyping={onTyping}
            sendOnTypingAlert={sendOnTypingAlert}
            getOktaAndScrub={getOktaAndScrub}
            isinputBarEnabled={isinputBarEnabled}
            placeholderMessage={placeholderMessage}
            isNetworkError={
              restartFlag || isNetworkError || isConnectParticipantTokenInvalid
            }
            adjustSound={adjustSound}
            printChat={printChat}
            defaultMessage={messageToScrub}
          />
        </>
      )}

      {renderOfChatPane.closeChatDialogBox && (
        <div className="endChatWindow -oneX-row">
          <div className="endChatPopUp">
            <div
              className={
                config.customerFacing === "false"
                  ? "insideEndChat"
                  : "insideEndChatCustomerFacing"
              }
            >
              <div className="end-text">
                <p className="endTextStyle endChatSpace">{t("endChatQues")}</p>
              </div>
              <div className="pop-up-buttons">
                <button
                  className="-oneX-btn-secondary -oneX-btn-medium no-button"
                  onClick={(event) => onNoToEndChat(event)}
                >
                  {t("No")}
                </button>
                <button
                  className="-oneX-btn-primary -oneX-btn-medium yes-button"
                  onClick={(event) => onYesToEndChat(event)}
                >
                  {t("Yes")}
                </button>
              </div>
            </div>
          </div>
          {!JSON.parse(config.customerFacing) && (
            <div className="printTranscript">
              <div className="endChat">
                <button
                  type="submit"
                  id="printButtonID"
                  className="-oneX-btn-secondary -oneX-btn-medium print-button endChat"
                  aria-label={t("printButtonMessage")}
                  tabIndex="0"
                  onClick={(event) => printChat(event)}
                >
                  {t("printButtonMessage")}
                </button>
              </div>
              <div className="messageBox">
                <p className="messageStyle">
                  Please ensure sharing or storage of the transcript maintains
                  appropriate confidentiality of the information. As a reminder,
                  State Farm agents are independent contractors who hire their
                  own employees. State Farm agents employees are not employees
                  of State Farm. Agents are responsible for and make all
                  employment decisions regarding their employees.
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
//--------------------------------------------------------------------//
// Chat Widget component Ends Here.
//--------------------------------------------------------------------//

export default Chat;
