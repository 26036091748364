import "amazon-connect-chatjs";
import { uploadChatLogs } from "./ChatAPI";
import {
  ConnectAccessDeniedError,
  NetworkingError,
  UnKnownError,
} from "../common/CustomError";
import { checkForTestScenario, uuidv4 } from "../common/ChatUtil";
import { logger } from "../common/Logger";

export class AmazonConnectParticipantAPI {
  config;
  globalConfig;
  session;
  eventHandler = {
    onWsConnectionNetworkError: undefined, //onWsConnectionNetworkErrorEventHandler,
    onConnectionEstablished: undefined, //onWsConnectionEstablishedEventHandler,
    onConnectionBroken: undefined, //onWsConnectionBrokenEventHandler,
    onConnectionLost: undefined, //onWsConnectionLostEventHandler,
    onEnded: undefined, //onWsSessionEndedEventHandler,
    onMessage: undefined, //onWsMessageEventHandler,
    onTyping: undefined, //onWsTypingEventHandler,
  };

  constructor({ eventHandler }) {
    logger.debug("AmazonConnectParticipantAPI: constructor started.");
    this.eventHandler = eventHandler;
    this.errorOnConnect = false;
    this.errorOnGetTranscript = false;
  }

  async connect(config) {
    logger.debug("AmazonConnectParticipantAPI: connect started.");
    this.config = config;

    // eslint-disable-next-line no-undef
    this.globalConfig = {
      loggerConfig: {
        logger: {
          debug: (data) => {
            // console.debug(data);
          },
          info: (data) => {
            // console.info(data);
          },
          warn: (data) => {
            console.warn(data);
          },
          error: (data) => {
            console.error(data);
            this.eventHandler.onWsConnectionNetworkError(data.toString());
            //         "Error when creating connection request ",{"type":"NetworkingError","message":"Network Failure","stack":["NetworkingError: Network Failure","    at XMLHttpRequest.<anonymous> (http://localhost:3000/static/js/1.chunk.js:34736:32)"]}] {"contactId":"e691a0a1-be28-48cc-94f8-996fee8f6917","participantId":"a1a42ab3-3a6c-4f91-9578-271b9a337134","sessionType":"CUSTOMER","region":"us-west-2"}
          },
        },
        // eslint-disable-next-line no-undef
        level: connect.ChatSession.LogLevel.INFO,
      },
      region: this.config.RegionName,
      //Control switch for enabling/disabling message-receipts (Read/Delivered) for messages
      //message receipts use sendEvent API for sending Read/Delivered events https://docs.aws.amazon.com/connect-participant/latest/APIReference/API_SendEvent.html
      features: {
        messageReceipts: {
          shouldSendMessageReceipts: true, // DEFAULT: true, set to false to disable Read/Delivered receipts
          throttleTime: 5000, //default throttle time - time to wait before sending Read/Delivered receipt.
        },
      },
    };
    // eslint-disable-next-line no-undef
    connect.ChatSession.setGlobalConfig(this.globalConfig);

    try {
      let ParticipantToken = this.config.chatDetails.ParticipantToken;
      ParticipantToken = checkForTestScenario(
        this.config.additionalAttributes?.CTM_test,
        "trigger-ws-connection-403",
        !this.errorOnConnect,
        "invalidTokenTest" + Math.random(),
        ParticipantToken
      );
      ParticipantToken = checkForTestScenario(
        this.config.additionalAttributes["CTM_test"],
        "trigger-ws-connection-error",
        !this.errorOnConnect,
        true,
        ParticipantToken
      );
      logger.debug(" connect tempChatDetails:", {
        errorOnConnect: this.errorOnConnect,
        ParticipantToken,
        originalParticipantToken: this.config.chatDetails.ParticipantToken,
      });

      // eslint-disable-next-line no-undef
      this.session = connect.ChatSession.create({
        chatDetails: {
          ...this.config.chatDetails,
          ParticipantToken,
        },
        type: "CUSTOMER",
      });
      this.session.sessionID = uuidv4();
      this.config.sessionID = this.session.sessionID;
      logger.debug(
        "AmazonConnectParticipantAPI: constructor ended.",
        this.config.sessionID
      );

      //register event handler to the session
      this.session.onConnectionEstablished(
        this.eventHandler.onConnectionEstablished
      );
      this.session.onConnectionBroken(this.eventHandler.onConnectionBroken);
      this.session.onConnectionLost(this.eventHandler.onConnectionLost);
      this.session.onEnded(this.eventHandler.onEnded);
      this.session.onMessage(this.eventHandler.onMessage);
      this.session.onTyping(this.eventHandler.onTyping);
      this.session.onDeliveredReceipt(this.eventHandler.onDeliveredReceipt);
      this.session.onReadReceipt(this.eventHandler.onReadReceipt);
      // this.session.onDeepHeartbeatFailure((event) => {
      //   // const { chatDetails, data } = event;
      //   logger.debug(
      //     "AmazonConnectParticipantAPI: onDeepHeartbeatFailure response: ",
      //     event
      //   );
      // });

      // connectCalled: indicates whether the Amazon Connect Participant Service was called
      // connectSuccess: indicates whether the operation succeeded
      const { connectCalled, connectSuccess } = await this.session.connect();
      logger.debug("AmazonConnectParticipantAPI: connect response.", {
        connectCalled,
        connectSuccess,
      });

      this.errorOnConnect = false;
      uploadChatLogs(this.config, {
        eventStr: "onSessionConnected-SUCCESS",
        response: JSON.stringify({ connectCalled, connectSuccess }),
      });
      return this.session;
    } catch (error) {
      this.errorOnConnect = true;
      this.handleWebSocketError(JSON.stringify(error), {
        eventStr: "onConnect-ERROR",
      });
    }
  }

  async disconnect() {
    try {
      const connectionStatus = await this.getConnectionStatus();
      logger.debug("connectionStatus before disconnect", connectionStatus);
      if (connectionStatus !== "Established") return false;

      const response = await this.session.controller.disconnectParticipant();
      uploadChatLogs(this.config, {
        eventStr: "onEnded-CUSTOMER-SUCCESS",
      });
      logger.debug(
        "AmazonConnectParticipantAPI: disconnect response: ",
        response
      );
      return true;
    } catch (err) {
      this.handleWebSocketError(
        JSON.stringify(err),
        { eventStr: "onDisconnect-CUSTOMER-ERROR" },
        false
      );
    }
  }

  async getConnectionStatus() {
    try {
      if (this.session && this.session.controller) {
        const response = await this.session.controller.getConnectionStatus({});
        logger.debug(
          "AmazonConnectParticipantAPI: getConnectionStatus response: ",
          response
        );
        return response;
      }
    } catch (err) {
      this.handleWebSocketError(
        err,
        { eventStr: "onGetConnectionStatus-ERROR" },
        false
      );
    }
  }

  async sendTypingEvent(value) {
    try {
      if (value.length !== 1) {
        checkForTestScenario(
          value,
          "trigger-ws-sendevent-403",
          true,
          new Error("AccessDeniedException")
        );
        checkForTestScenario(
          value,
          "trigger-ws-sendevent-error",
          true,
          new Error("Generic Error test")
        );
        return;
      }
      const response = await this.session.controller.sendEvent({
        contentType: "application/vnd.amazonaws.connect.event.typing",
      });
      logger.debug(
        "AmazonConnectParticipantAPI: sendTypingEvent response: ",
        response
      );
      return response;
    } catch (err) {
      this.handleWebSocketError(err, { eventStr: "onSendTypingEvent-ERROR" });
    }
  }

  async sendMessage(messageContent) {
    try {
      checkForTestScenario(
        messageContent,
        "trigger-ws-sendmessage-403",
        true,
        new ConnectAccessDeniedError("AccessDeniedException")
      );

      // check messageContent for TestScenario trigger-ws-sendmessage-error, if match then replace the messageContent with true
      // this will trigger an IllegalArgument error
      const message = checkForTestScenario(
        messageContent,
        "trigger-ws-sendmessage-error",
        true,
        true,
        messageContent
      );

      logger.debug("sendMessage ", message);
      const response = await this.session.controller.sendMessage({
        message: message,
        contentType: "text/plain",
      });
      logger.debug(
        "AmazonConnectParticipantAPI: sendMessage response: ",
        response
      );
      return response;
    } catch (err) {
      this.handleWebSocketError(JSON.stringify(err), {
        eventStr: "onSendMessage-ERROR",
      });
    }
  }

  async getTranscript() {
    if (this.session.controller.connectionDetails === undefined) {
      return { Transcript: [] };
    }

    let connectionToken =
      this.session.controller.connectionDetails.connectionToken;
    connectionToken = checkForTestScenario(
      this.config.additionalAttributes["CTM_test"],
      "trigger-ws-transcript-403",
      !this.errorOnGetTranscript,
      "invalidTokenTest" + Math.random(),
      connectionToken
    );

    let headers = {
      "Content-Type": "application/json",
      "X-Amz-Bearer": connectionToken,
    };
    headers = checkForTestScenario(
      this.config.additionalAttributes["CTM_test"],
      "trigger-ws-transcript-error",
      !this.errorOnGetTranscript,
      { "Content-Type": "application/json" },
      headers
    );

    logger.debug("Headers used within getTranscript api: ", {
      errorOnGetTranscript: this.errorOnGetTranscript,
      headers,
      connectionTokenExisting:
        this.session.controller.connectionDetails.connectionToken,
    });

    const body = {
      MaxResults: 100,
      ScanDirection: "BACKWARD",
      SortOrder: "ASCENDING",
      StartPosition: {},
    };

    try {
      const response = await fetch(
        `https://participant.connect.${this.config.RegionName}.amazonaws.com/participant/transcript`,
        {
          method: "POST",
          cache: "no-cache",
          headers,
          body: JSON.stringify(body), // body data type must match "Content-Type" header
        }
      );
      logger.debug(
        "AmazonConnectParticipantAPI: getTranscript response: ",
        response
      );

      const data = await response.json(); // parses JSON response into native JavaScript objects
      logger.debug(
        "AmazonConnectParticipantAPI: getTranscript response.data: ",
        data
      );
      if (!response.ok || response.status !== 200) {
        throw new Error(data.message);
      }

      this.errorOnGetTranscript = false;
      return data;
    } catch (error) {
      this.errorOnGetTranscript = true;
      this.handleWebSocketError(error, { eventStr: "onGetTranscript-ERROR" });
    }
  }

  handleWebSocketError(err, eventLog, throwError = true) {
    const error = err?._debug?._debug ? err._debug._debug : err;
    logger.error(
      "AmazonConnectParticipantAPI: Entered handleWebSocketError()",
      {
        event: eventLog?.eventStr,
        error: error,
        err: error.toString(),
      }
    );

    uploadChatLogs(this.config, {
      ...eventLog,
      error: JSON.stringify(error),
      errorMessage: error.toString(),
    });

    if (!throwError) {
      return;
    } else if (
      error?.statusCode === 403 ||
      error?.message?.includes(
        "User is not authorized to access this resource with an explicit deny"
      ) ||
      error?.type === "AccessDeniedException" ||
      error.toString()?.includes("AccessDeniedException") ||
      error
        .toString()
        ?.includes(
          "User is not authorized to access this resource with an explicit deny"
        ) ||
      JSON.stringify(error).includes("AccessDeniedException") ||
      JSON.stringify(error).includes(
        "User is not authorized to access this resource with an explicit deny"
      )
    ) {
      logger.error("handleWebSocketError - found AccessDeniedError", error);
      throw new ConnectAccessDeniedError(
        "Expired or Invalid Connect ParticipantToken"
      );
    } else if (
      err.toString().includes("Network Failure") ||
      error?.message?.includes("Network Failure")
    ) {
      throw new NetworkingError("Network Failure");
    } else {
      throw new UnKnownError(err);
    }
  }
}
