import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "./InputContainer.css";
import ActionBar from "./ActionBar/ActionBar";
import { t } from "i18next";
import CharCounter from "./CharCounter/CharCounter.js";

// skip focus on intial render
function useDidComponentMount(callback) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) return callback();
    else didMountRef.current = true;
  }, [callback]);
}
const InputContainer = ({
  onTyping,
  sendOnTypingAlert,
  getOktaAndScrub,
  isinputBarEnabled,
  placeholderMessage,
  isNetworkError,
  adjustSound,
  printChat,
  defaultMessage,
}) => {
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };

  const [message, setMessage] = useState(defaultMessage);
  const [action, setAction] = useState(true);
  const inputContainerRef = useRef();
  const chevronDownRef = useRef();
  const chevronUpRef = useRef();
  const printChatRef = useRef();
  const [inputRef, setInputFocus] = useFocus();
  const [muteButton, setMuteButton] = useState(true);
  const charLimit = useRef(500);

  const onMuteAction = (event) => {
    setMuteButton(event);
  };

  //to fix single character typing in input box
  useEffect(() => {
    if (action) {
      if (inputRef && window.screen.width > 678) {
        inputRef.current.focus();
      }
    }
  });

  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(inputContainerRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(inputContainerRef);
      }
    };
  });

  // after inital render, return focus to plus button when action bar is closed
  const setFocus = useCallback(() => {
    if (action) {
      returnFocus("menu");
    }
    //else for focus to go on Chevron down when menu is expanded
    else {
      returnFocus("");
    }
  }, [action]);

  useDidComponentMount(setFocus);

  const scrubMessageAndSend = async (event) => {
    event.preventDefault();
    try {
      await getOktaAndScrub(document.getElementById("chatTextBoxId").value);
      setMessage("");
    } catch (e) {
      console.log("from inputContainer scrubmessage() error obj: ", {
        e,
        message,
      });
    }
  };

  const onClickAction = (event) => {
    if (event.key === "Escape") {
      setAction(true);
      return false;
    }
    if (event.key === "Tab" && !action) {
      event.preventDefault();
      printChatRef.current.focus();
      return false;
    }
    if (event.key === "Enter") {
      setAction(!action);
      setMessage("");
      return false;
    }
  };

  const returnFocus = (element) => {
    if (element === "menu") chevronDownRef.current.focus();
    else chevronUpRef.current.focus();
  };
  var limit = 87; //height limit
  const reseizeHeight = () => {
    inputRef.current.style.height = "";
    inputRef.current.style.height =
      Math.min(inputRef.current.scrollHeight, limit) + "px";
  };
  useEffect(() => {
    reseizeHeight();
  });
  const updateMessage = (value) => {
    setMessage(value);
    sendOnTypingAlert(value);
  };

  const onKeyPressSend = (event) => {
    if (event.key === "Enter" && isinputBarEnabled) {
      scrubMessageAndSend(event);
    }

    if (event.key === "Tab") {
      if (event.shiftKey) {
        inputContainerRef.current.focus();
      }
    }
  };

  return (
    <>
      {onTyping && (
        <div className="repTyping">
          <div className=" footer" role="alert">
            {t("repTypingMessage")}
          </div>
        </div>
      )}

      <div
        className={
          onTyping ? "" : isNetworkError ? "newDiv-lost-conn" : "newDiv"
        }
      >
        <div
          className={
            isNetworkError
              ? "-oneX-container inputContainer ic-opacity-lost-conn"
              : "-oneX-container inputContainer"
          }
          aria-live="polite"
        >
          <div ref={inputContainerRef} className="-oneX-row inputRow">
            {/* Chevron down button code */}

            <div
              className={
                action
                  ? "-oneX-col-1 -oneX-icon--chevron--down -oneX-icon-container -oneX-icon--interactive chevronMenu"
                  : "hide-display "
              }
              id="chevronDownId"
              ref={chevronDownRef}
              tabIndex="0"
              role="button"
              title={t("chevronDownTitle")}
              onClick={(event) => {
                setAction(!action);
                return false;
              }}
              onKeyDown={(event) => onClickAction(event)}
            ></div>

            {/* Chevron Up code */}
            <div
              className={
                action
                  ? "hide-display "
                  : "-oneX-col-1 -oneX-icon--chevron--up -oneX-icon-container -oneX-icon--interactive chevronMenu"
              }
              id="chevronUpId"
              ref={chevronUpRef}
              tabIndex="0"
              title={t("chevronUpTitle")}
              role="button"
              onClick={(event) => {
                setAction(!action);
                return false;
              }}
              onKeyDown={(event) => onClickAction(event)}
            ></div>

            {/* Input text area */}
            <div className="-oneX-col-10">
              <div className={"textAreaDiv"}>
                {/* console.log(
                  "placeholderMessage.length, message: " +
                    placeholderMessage.length,
                  message
                ) */}
                <textarea
                  maxLength="500"
                  tabIndex="0"
                  style={{
                    resize: "none",
                  }}
                  placeholder={placeholderMessage}
                  id="chatTextBoxId"
                  className={
                    "-oneX-textfield--floating-input no-border text-area" +
                    (placeholderMessage?.length < 40
                      ? ""
                      : " add-less-top-padding")
                  }
                  autoComplete="off"
                  //autoFocus={true}
                  aria-label={placeholderMessage}
                  aria-required="false"
                  aria-labelledby="chatTextBoxLabelId"
                  aria-multiline="true"
                  disabled={!isinputBarEnabled || !action || isNetworkError} // or condition if action is true
                  value={message}
                  ref={inputRef}
                  onChange={(event) => {
                    updateMessage(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    return event.key === "Enter"
                      ? scrubMessageAndSend(event)
                      : null;
                  }}
                ></textarea>
              </div>
            </div>

            {/* Char counter */}
            <div className="-oneX-col-1 -oneX-icon-container">
              <div className="char-count">
                {message?.length > 200 && charLimit?.current && (
                  <CharCounter
                    charCount={message?.length}
                    charLimit={charLimit?.current}
                  />
                )}
              </div>

              {/* Send button */}
              <div
                id="sendMessageID"
                className={
                  isNetworkError
                    ? "-oneX-icon forwardIcon" +
                      (!isinputBarEnabled || !action ? " disable-send" : "") +
                      " opacity-lost-conn"
                    : "-oneX-icon forwardIcon" +
                      (!isinputBarEnabled || !action ? " disable-send" : "")
                }
                data-icon="arrow_right_24"
                role="button"
                tabIndex="0"
                title={t("sendMessageTitle")}
                onClick={(event) => scrubMessageAndSend(event)}
                onKeyDown={(event) => onKeyPressSend(event)}
                aria-disabled={!isinputBarEnabled || !action}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {!action && (
        <ActionBar
          printChat={printChat}
          adjustSound={adjustSound}
          onClickAction={onClickAction}
          returnFocus={returnFocus}
          printChatRef={printChatRef}
          onMuteAction={onMuteAction}
          muteButton={muteButton}
        />
      )}
    </>
  );
};

export default InputContainer;
