export class CustomError extends Error {
  constructor(msg) {
    super(msg);
    this.name = this.constructor.name;
  }
}

export class ConfigKeyNotFoundError extends CustomError {}
export class ConfigKeyMissingDataError extends CustomError {}
export class ChatWidgetVersionMismatchError extends CustomError {}

export class SendMessageUnexpectedError extends CustomError {}
export class ConnectAccessDeniedError extends CustomError {}
export class OktaAccessDeniedError extends CustomError {}
export class AzureAccessDeniedError extends CustomError {}

export class AzureAuthenticationLoginInprogressError extends CustomError {}
export class AzureAuthenticationLoginCancelledError extends CustomError {}
export class AzureAuthenticationLoginPopupBlockedError extends CustomError {}
export class AzureAuthenticationLoginPopupUnknownError extends CustomError {}
export class AzureAuthenticationSSOSilentUnknownError extends CustomError {}
export class AzureAuthenticationTokenSilentUnknownError extends CustomError {}

export class NetworkingError extends CustomError {}
export class UnKnownError extends CustomError {}
