import React, { useState } from "react";
import { uuidv4, scrollDown } from "../../../common/ChatUtil.js";

const Image = ({ url, tooltip, style, isNetworkError }) => {
  return (
    <div className="image" style={style}>
      <img
        key={uuidv4()}
        src={url}
        alt={tooltip}
        onLoad={scrollDown}
        className={isNetworkError ? "opacity-lost-conn" : ""}
      />
    </div>
  );
};

export default Image;
