import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Notification from "./Notification/Notification.js";
import Message from "./Message/Message.js";
import "./MessageList.css";
import QuickReplies from "./QuickReplies/QuickReplies.js";
import BotWaitingIndicator from "./WaitingIndicators/BotWaitingIndicator.js";
import RichContent from "./RichContent/RichContent.js";
import { scrollDown } from "../../common/ChatUtil.js";
import { logger } from "../../common/Logger.js";

const MessageList = ({
  messageList,
  divRef,
  sendMessage,
  agentHasJoinedConversation,
  onTyping,
  isNetworkError,
  isCustomerMessageInView,
}) => {
  const messageListRef = useRef();
  window.oneX.addElement(document.querySelector("#scrollToButton"));
  function scrollToTopOfMessage() {
    /* var elementToScrollTo =
      document.getElementsByClassName("displayname right"); */
    var elementToScrollTo = document.getElementsByClassName(
      "newMessageIndicator"
    );
    logger.debug(
      "*** elementToSrollTo: ",
      elementToScrollTo[elementToScrollTo.length - 1]
    );
    /*  const y = elementToScrollTo.getBoundingClientRect() + window.pageYOffset;
    window.scrollTo({ top: y, behavior: "smooth" }); */
    elementToScrollTo[elementToScrollTo.length - 1].scrollIntoView(true);
    document
      .querySelector(".scrollToButton")
      .style.setProperty("visibility", "hidden");
  }

  function isElementInView(ele, messageListContainer) {
    if (ele !== null && !agentHasJoinedConversation) {
      console.log(
        "**** agentHasJoinedConversation: ",
        agentHasJoinedConversation
      );
      // get contianer properties
      let messageListContainerTop = messageListContainer.scrollTop;
      let messageListContainerBottom =
        messageListContainerTop + messageListContainer.clientHeight;

      //get element propeties
      let eleTop = ele.offsetTop;
      let eleBottom = eleTop + ele.clientHeight;

      if (
        eleTop >= messageListContainerTop /*&& 
        eleBottom <= messageListContainerBottom */
      ) {
        document
          .querySelector(".scrollToButton")
          .style.setProperty("visibility", "hidden");
        // added to new message indicator for short messages
        var newIndiactorMessageList = document.getElementsByClassName(
          "newMessageIndicator"
        );
        var newIndiactorMessageListLength = newIndiactorMessageList.length;
        for (var i = 0; i < newIndiactorMessageListLength; i++) {
          if (i != newIndiactorMessageListLength - 1) {
            newIndiactorMessageList[i].style.display = "none";
          }
        }
        return true;
      } else {
        var elementToScrollTo =
          document.getElementsByClassName("displayname left");
        var lastScrollToElement =
          elementToScrollTo[elementToScrollTo.length - 1];

        if (
          lastScrollToElement.getAttribute("previouslyActivated") === null ||
          !lastScrollToElement.getAttribute("previouslyActivated")
        ) {
          console.log("*** inside if check to set visibility visible");
          document
            .querySelector(".scrollToButton")
            .style.setProperty("visibility", "visible");

          // hides previous new message indicator
          var newIndiactorMessageList = document.getElementsByClassName(
            "newMessageIndicator"
          );
          var newIndiactorMessageListLength = newIndiactorMessageList.length;
          newIndiactorMessageList[
            newIndiactorMessageListLength - 1
          ].style.display = "flex";
          for (var i = 0; i < newIndiactorMessageListLength; i++) {
            if (i != newIndiactorMessageListLength - 1) {
              newIndiactorMessageList[i].style.display = "none";
            }
          }
        }

        lastScrollToElement.setAttribute("previouslyActivated", true);
        return false;
      }
    }
  }

  //const [scrolledUp, setScrolledUP] = useState(false);
  isCustomerMessageInView = true;

  //Hooks to handle scrolling of message Div. Registers a scroll event listener.
  useEffect(() => {
    const handleScroll = () => {
      const messageListContainer = document.querySelector(
        ".messageListContainer"
      );
      let scrollToELementList =
        document.getElementsByClassName("displayname left");
      let ele = scrollToELementList[scrollToELementList.length - 1];
      const chatMessageContainer = document.querySelector(
        ".messageListContainer"
      );
      let scrollTopVal = Math.round(chatMessageContainer.scrollTop);
      if (
        scrollTopVal + chatMessageContainer.clientHeight <
        chatMessageContainer.scrollHeight
      ) {
        isElementInView(ele, messageListContainer);
        //setScrolledUP(true);
      } else {
        if (
          document.getElementsByClassName("displayname")[
            document.getElementsByClassName("displayname").length
          ] !== "displayname right"
        ) {
          isCustomerMessageInView = isElementInView(ele, messageListContainer);
        }
        scrollDown();
        //setScrolledUP(false);
      }
    };

    if (document.querySelector(".messageListContainer"))
      document
        .querySelector(".messageListContainer")
        .addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      if (document.querySelector(".messageListContainer"))
        document
          .querySelector(".messageListContainer")
          .removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //When a new message is added, either autoscroll or update unread count
  // useEffect(() => {
  //   // let lastMsg = messageList[messageList.length - 1];
  //   if (
  //     divRef !== null &&
  //     divRef !== undefined &&
  //     divRef.current !== null &&
  //     divRef.current !== undefined
  //   ) {
  //     console.log("*** uses effect triggered");
  //     const ele = document.getElementById("customerResponse");
  //     const messageListContainer = document.querySelector(
  //       ".messageListContainer"
  //     );
  //     //isCustomerMessageInView = isElementInView(ele, messageListContainer);

  //     // console.log(
  //     //   "*** isCustomerMessageInView inside add message use effect: ",
  //     //   isCustomerMessageInView
  //     // );
  //     /*   if (isCustomerMessageInView) {
  //       document.querySelector(".scrollToButton").style.visibilty = "hidden";
  //     } */

  //     // if (scrolledUp) {
  //     //   if (!scrolledUp) {
  //     //     const chatMessageContainer = document.querySelector(
  //     //       ".messageListContainer"
  //     //     );
  //     //     if (
  //     //       chatMessageContainer.scrollTop + chatMessageContainer.clientHeight <
  //     //       chatMessageContainer.scrollHeight
  //     //     ) {
  //     //       setScrolledUP(true);
  //     //     }
  //     //   }
  //     // }
  //     scrollDown();
  //     // const chatMessageContainer = document.querySelector(
  //     //   ".messageListContainer"
  //     // );
  //     // chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [messageList.length, isNetworkError, onTyping]);

  useLayoutEffect(() => {
    if (window.oneX) {
      window.oneX.addElement(messageListRef.current);
    }
    return () => {
      if (window.oneX) {
        window.oneX.removeElement(messageListRef);
      }
    };
  });

  const waitingForBotResponse =
    messageList.length > 0 &&
    !agentHasJoinedConversation &&
    messageList[messageList.length - 1].isCustomer;

  //return Message List UI
  return (
    <div
      className={
        isNetworkError
          ? "messageListContainer messageListContainerOpacity"
          : "messageListContainer"
      }
      tabIndex={-1}
      ref={messageListRef}
    >
      {isCustomerMessageInView && !agentHasJoinedConversation && (
        <div className="scrollToButtonDiv -oneX">
          <button
            className={
              isNetworkError
                ? "-oneX-btn-primary -oneX-btn-small -oneX-icon-button -oneX-btn-fit-content scrollToButtonOpacity"
                : "-oneX-btn-primary -oneX-btn-small -oneX-icon-button -oneX-btn-fit-content scrollToButton"
            }
            onClick={(event) =>
              //document.getElementById("customerResponse").scrollIntoView()
              scrollToTopOfMessage()
            }
          >
            <span className="-oneX-icon-container -oneX-icon-btn-container arrowIconDiv">
              <div
                className="-oneX-icon arrowUpStyle"
                data-icon="arrow_up_24"
                //style={{ height: "16px", width: "16px" }}
              ></div>
            </span>
            <span>New messages</span>
          </button>
        </div>
      )}
      <div className="hide-error">
        <Notification type="error" /* unreadCount={unreadCount} */>
          Connection Timed out, please try again
        </Notification>
      </div>
      {messageList.map((message, i) => {
        if (
          typeof message.messageText === "string" ||
          message.isCustomer ||
          message.messageText?.templateType
        ) {
          return (
            <div key={i} ref={divRef}>
              <Message
                key={i}
                //isInteractiveMessageClicked -  condition is for PACE persist to hide the List Picker buttons post selection and refresh
                isInteractiveMessageClicked={
                  message.messageText.templateType === "ListPicker" &&
                  i === messageList.length - 1
                    ? false
                    : true
                }
                messageText={message.messageText}
                messageUserName={message.messageUserName}
                isCustomer={message.isCustomer}
                isAgent={message.isAgent}
                isSystem={message.isSystem}
                isEvent={message.isEvent}
                displayUserName={message.displayUserName}
                messageTime={message.messageTime}
                id={message.id}
                repAlias={message.repAlias}
                delayMessage={message.delayMessage}
                sendMessage={sendMessage}
                isNetworkError={isNetworkError}
                readDeliveryStatus={message.readDeliveryStatus}
              />
            </div>
          );
        } else if (message.messageText.type === "quickReplies") {
          if (i === messageList.length - 1) {
            return (
              <div key={i} ref={divRef}>
                <QuickReplies
                  quickReplies={message.messageText.replies}
                  sendMessage={sendMessage}
                  delayMessage={message.delayMessage}
                  id={message.id}
                  isNetworkError={isNetworkError}
                />
              </div>
            );
          } else {
            return undefined;
          }
        } else {
          return (
            <div key={i} ref={divRef}>
              <RichContent
                message={message}
                sendMessage={sendMessage}
                displayUserName={message.displayUserName}
                messageUserName={message.messageUserName}
                messageTime={message.messageTime}
                divRef={divRef}
                isNetworkError={isNetworkError}
                isSystem={message.isSystem}
              />
            </div>
          );
        }
      })}
      {waitingForBotResponse && (
        <BotWaitingIndicator
          isNetworkError={isNetworkError}
        ></BotWaitingIndicator>
      )}
    </div>
  );
};

export default MessageList;
