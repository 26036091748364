import React from "react";
import Text from "./Text.js";
import Button from "./Button.js";
import Image from "./Image.js";
import Carousel from "./Carousel.js";
import Horizontal from "./Horizontal.js";
import Vertical from "./Vertical.js";

const richContentMap = {
  button: Button,
  text: Text,
  horizontal: Horizontal,
  vertical: Vertical,
  image: Image,
  carousel: Carousel,
};
const RecursiveComponent = ({ message, sendMessage, isNetworkError }) => {
  const Ele = richContentMap[message.type];
  // const hasChildren = elements && elements.length;
  // const styleWidth = (1 / elements.length) * 100 + "%";

  //const elementsLength = elements ? elements.length : 0;

  return (
    <Ele
      {...message}
      sendMessage={sendMessage}
      richContentMap={richContentMap}
      isNetworkError={isNetworkError}
    />
  );
};

export default RecursiveComponent;
