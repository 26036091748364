import React from "react";

import "./QuickReplies.css";

const QuickReplies = ({
  quickReplies,
  sendMessage,
  delayMessage,
  id,
  isNetworkError,
}) => {
  const handleQuickReplyClick = async (e) => {
    try {
      if (isNetworkError) {
        e.preventDefault();
      } else {
        if (id !== "richContent") {
          document.getElementById(id).hidden = true;
        }
        await sendMessage(e.target.title);
      }
    } catch (error) {
      console.error("QUICK REPLY CATCH!!!!!", error);
      if (id !== "richContent") {
        document.getElementById(id).hidden = false;
      }
    }
  };

  const buildQuickReply = ({ tooltip, title, click }, i) => (
    <button
      key={i}
      className={
        isNetworkError
          ? "-oneX-btn-secondary -oneX-btn-small quick-reply opacity-lost-conn"
          : "-oneX-btn-secondary -oneX-btn-small quick-reply"
      }
      tooltip={tooltip}
      title={click.actions.find(({ type }) => type === "publishText").text}
      onClick={handleQuickReplyClick}
      tabIndex={0}
    >
      {title}
    </button>
  );

  // 3 or less stacked, 4 or more wrap to fit
  return (
    <div
      id={id}
      hidden={delayMessage ? true : false}
      className={
        "quick-replies-container four-or-more" +
        (quickReplies[0].title === "Edit" ? " rightAlignButton" : "")
      }
      style={{}}
    >
      {quickReplies.map(buildQuickReply)}
    </div>
  );
};

export default QuickReplies;
