//print chat by opening a window.
export const printChatUtility = (transcript, config) => {
  var myWindow = window.open("", "myWindow");
  transcript.forEach((message) => {
    switch (message.userType) {
      case "CUSTOMER":
        createPNodeAndText(
          myWindow,
          config.userName + ": " + message.messageText
        );
        break;

      case "EVENT":
        createPNodeAndAddInnerHTML(myWindow, message.messageText);
        break;

      default:
        if (message.messageText?.templateType === "ListPicker") {
          createPNodeAndText(
            myWindow,
            message.messageUserName +
              ": " +
              message.messageText.data.content.title
          );
          break;
        } else if (message.messageText?.type === "quickReplies") {
          return "";
        }
        var agentAliasValue =
          message.isAgent && message.repAlias
            ? "(" + message.repAlias + ")"
            : "";
        createPNodeAndAddInnerHTML(
          myWindow,
          message.messageUserName +
            " " +
            agentAliasValue +
            ": " +
            message.messageText
        );
        break;
    }
    createPNodeAndText(myWindow, message.messageTime);
    return myWindow;
  });
  myWindow.print();
  return false;
};

function createPNodeAndText(myWindow, textValue) {
  var pNode = myWindow.document.createElement("p");
  pNode.appendChild(myWindow.document.createTextNode(textValue));
  myWindow.document.body.appendChild(pNode);
}
function createPNodeAndAddInnerHTML(myWindow, textValue) {
  var pNode = myWindow.document.createElement("p");
  pNode.innerHTML = textValue;
  myWindow.document.body.appendChild(pNode);
}
