import React from "react";

const Button = ({ click, style, title, sendMessage, isNetworkError }) => {
  const link = click.actions.find(({ type }) => type === "link");
  const publishText = click.actions.find(({ type }) => type === "publishText");

  async function handleClick(e) {
    if (isNetworkError) {
      e.preventDefault();
    } else {
      if (publishText) {
        e.preventDefault();
        await sendMessage(publishText.text);
      }
    }
  }

  return (
    <div className="button" style={style}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link?.uri || "#"}
        tabIndex={0}
        className={
          isNetworkError
            ? "-oneX-link--block opacity-lost-conn"
            : "-oneX-link--block"
        }
        onClick={handleClick}
      >
        {title}
      </a>
    </div>
  );
  // }
};

export default Button;
